import React, { useEffect, useMemo, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Helmet from "react-helmet";
import {useTranslation} from "react-i18next";
import ImageGallery from "react-image-gallery";
import {connect, useDispatch} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {StringParam, useQueryParam} from "use-query-params";
import AddedToCartModal from "../../../components/AddedToCartModal/AddedToCartModal";
import AmountInput from "../../../components/AmountInput/AmountInput";
import ArrowBannerMountedWheel from "../../../components/ArrowBannerMountedWheel/ArrowBannerMountedWheel";
import CarSelection from "../../../components/CarSelection/CarSelection";
import CarSelectionMobile from "../../../components/CarSelectionMobile/CarSelectionMobile";
import CarView from "../../../components/CarView/CarView";
import CustomModal from "../../../components/CustomModal/CustomModal";
import LoadingDetails from "../../../components/LoadingDetails/LoadingDetails";
import MultilineDropdown from "../../../components/MultilineDropdown/MultilineDropdown";
import ProductErrorMessage from "../../../components/ProductErrorMessage/ProductErrorMessage";
import {CarDetailDTO} from "../../../models/car/CarDetailDTO";
import RimPrice from "../../../components/RimPrice/RimPrice";
import {RimTyreSizeTable} from "../../../components/RimTyreSizeTable/RimTyreSizeTable";
import {TradeName} from "../../../components/TradeName/TradeName";
import {useMediaQuery} from "../../../helper/useMediaQuery";
import {
    RimDetailDTO,
    RimInformationDTO
} from "../../../models/rims/RimDetailDTO";
import {RimSizeAssignmentDTO} from "../../../models/rims/RimSizeAssignmentDTO";
import {RimTyreAssignmentDTO} from "../../../models/rims/RimTyreAssignmentDTO";
import {RimTyreAssignmentRequest} from "../../../models/rims/RimTyreAssignmentRequest";
import {Axle} from "../../../models/shared/Enums";
import {updateCartPreview} from "../../../redux-store/actions/cartPreviewAction";
import {getCarDetails} from "../../../redux-store/api/CarApi";
import {getRimDetails, getRimTyreAssignment} from "../../../redux-store/api/RimApi";
import {addMixedRimToShoppingCart, addRimToShoppingCart} from "../../../redux-store/api/ShoppingCartApi";
import AdditionalInformation from "./AdditionalInformation/AdditionalInformation";
import CertificateInformation from "./CertificateInformation/CertificateInformation";
import RimDetailsTable from "./RimDetailsTable/RimDetailsTable";
import RimRestrictions from "./RimRestrictions/RimRestrictions";
import BrakeRestrictions from "./BrakeRestrictions/BrakeRestrictions";
import TyreRestrictions from "./TyreRestrictions/TyreRestrictions";
import {RimTyreDTO} from "../../../models/rims/RimTyreDTO";
import {Orientation, useOrientation} from "../../../helper/useOrientation";
import {scrollToTopFn} from "../../../components/ScrollToTop";
import {FullScreenModeActionType} from "../../../redux-store/reducers/FullScreenModeReducer";

interface Props {
    updateCartPreview: Function;
}

function RimDetails(props: Props) {
    const {seoCarId, seoRimId} = useParams<{
        seoRimId: string,
        seoCarId: string
    }>();

    const [t] = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const isSmallScreen = useMediaQuery("(max-width: 991.98px)");
    const [carDetailsLoading, setCarDetailsLoading] = useState(false);
    const [rimDetailsLoading, setRimDetailsLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [amount, setAmount] = useState(4);
    const [carDetails, setCarDetails] = useState<CarDetailDTO>(null);
    const [rimDetails, setRimDetails] = useState<RimDetailDTO>(null);
    const [selectedRimSize, setSelectedRimSize] = useState<RimSizeAssignmentDTO>(null);
    const [rimSwitched, setRimSwitched] = useState(false);
    const [rimSizeAssignments, setRimSizeAssignments] = useState<RimSizeAssignmentDTO[]>([]);
    const [filteredRimSizeAssignments, setFilteredRimSizeAssignments] = useState<RimSizeAssignmentDTO[]>([]);
    const [error, setError] = useState(null);
    const [showAddedToCartModal, setShowAddedToCartModal] = useState(false);
    const [images, setImages] = useState([]);
    const [rimFrontColorAlternatives, setRimFrontColorAlternatives] = useState<RimInformationDTO[]>([]);
    const [rimRearColorAlternatives, setRimRearColorAlternatives] = useState<RimInformationDTO[]>([]);
    const [rimSizes, setRimSizes] = useState([]);
    const [rimTyreAssignments, setRimTyreAssignments] = useState<RimTyreAssignmentDTO[]>([]);
    const [rimTyreAssignmentsLoading, setRimTyreAssignmentsLoading] = useState(false);
    const galleryRef = useRef(null);
    const noImage = [{original: '/no_images/felge.svg'}];
    const orientation = useOrientation();
    const [fullscreen, setFullscreen] = useState(false);
    const dispatch = useDispatch();
    const [mobileRimFullScreen, setMobileRimFullScreen] = useState(false);
    const [availableQuantity, setAvailableQuantity] = useState(0);
    const [isReloadingDetails, setIsReloadingDetails] = useState<boolean>(false);
    const [isFirstSearch, setIsFirstSearch] = useState<boolean>(true);

    const [rimId, setRimId] = useState(seoRimId?.split('-')[0]);
    useEffect(() => {
        setRimId(seoRimId?.split('-')[0])
    }, [seoRimId]);

    const [initRimRearId] = useQueryParam('rimRearId', StringParam);
    const [rimRearId, setRimRearId] = useState(initRimRearId);

    const carId = useMemo(() => {
        return seoCarId?.split('-')[0]
    }, [seoCarId]);

    useEffect(() => {
        if(rimId) {
            loadRimDetails(rimId, rimRearId);
        }
        if (carId) {
            loadCarDetails();
        }
    }, [rimId, carId, rimRearId]);

    useEffect(() => {
        dispatch({type: FullScreenModeActionType.SET, payload: {isEnabled: false}})
    }, []);

    function loadRimDetails(frontId: string, rearId?: string, showLoading = true) {
        if (showLoading) {
            setRimDetailsLoading(true);
            scrollToTopFn();
        }

        getRimDetails(carId, frontId, rearId)
            .then((res) => {
                setRimDetails(res);
                setRimSizeAssignments(res.rimDetailsSizes);
                if (res.rimDetailsSizes?.length > 0) {
                    const selectedRimId = res.switched ? res.rimFront.rimExternalId : frontId;
                    let size;

                    const hasValidRearId = !!res.rimDetailsSizes.find((size) => size.front?.rimIdCode === selectedRimId && size.rear?.rimIdCode === rearId);

                    if (!rearId || !hasValidRearId) {
                        size = res.rimDetailsSizes
                            .filter((size) => size.front.rimIdCode === selectedRimId)
                            .sort((a, b) => {
                                if (a.rear?.rimIdCode === b.rear?.rimIdCode) {
                                    return 0;
                                } else {
                                    if (a.rear === null) {
                                        return -1;
                                    }
                                    if (b.rear === null) {
                                        return 1;
                                    }
                                    return a.rear.rimIdCode > b.rear.rimIdCode ? -1 : 1;
                                }
                            })[0];

                        new URLSearchParams(location.search).delete("rimRearId");
                    } else {
                        size = res.rimDetailsSizes.find((size) => size.front?.rimIdCode === selectedRimId && size.rear?.rimIdCode === rearId);
                    }

                    setAvailableQuantity(rearId && hasValidRearId
                        ? Math.min(res.rimRear?.priceAndStock?.quantity ?? 0, res.rimFront?.priceAndStock?.quantity ?? 0)
                        : res.rimFront?.priceAndStock?.quantity ?? 0
                    );
                    setSelectedRimSize(size);
                    loadRimTyreAssignments(size);
                    setFilteredRimSizeAssignments(res.rimDetailsSizes.filter(rsa => rsa.front?.rimSize?.split('x')[1] === size.front?.rimSize?.split('x')[1]));
                    setRimSizes(getRimSizeSelection(res.rimDetailsSizes).sort());

                    const rimFrontColorAlternativesTmp = res.rimFrontColorAlternatives;
                    rimFrontColorAlternativesTmp.push(res.rimFront);
                    setRimFrontColorAlternatives(
                        rimFrontColorAlternativesTmp.sort((a, b) => {
                            if (a.colour > b.colour) return 1;
                            if (a.colour < b.colour) return -1;

                            // If colours are the same, compare colourGroup
                            if (a.colorGroup > b.colorGroup) return 1;
                            if (a.colorGroup < b.colorGroup) return -1;

                            return 0;
                        })
                    );

                    setRimRearColorAlternatives(res.rimRearColorAlternatives.sort());
                    setRimSwitched(res.switched);
                } else {
                    console.error("Couldn't find rimSize");
                    setAvailableQuantity(res.rimFront.priceAndStock.quantity);
                }

                if (!(res.requestValid || (!res.requestValid && res.switched)) && !!carId) {
                    navigate("/felgenkonfigurator/" + encodeURIComponent(carId) + "?showAlternativeRims=1");
                }
                const images = [
                    {
                        original: res.rimFront.imagePerspectiveDTO.original,
                        thumbnail: res.rimFront.imagePerspectiveDTO.thumbnail,
                        fullscreen: res.rimFront.imagePerspectiveDTO.original
                    },
                    {
                        original: res.rimFront.imageFrontDTO.original,
                        thumbnail: res.rimFront.imageFrontDTO.thumbnail,
                        fullscreen: res.rimFront.imageFrontDTO.original
                    }
                ]
                setImages(images);
            })
            .catch((error) => {
                setError(error);
            }).finally(
            () => {
                setIsReloadingDetails(false);
                setRimDetailsLoading(false);
            }
        );
    }

    function loadCarDetails() {
        if (carId) {
            setCarDetailsLoading(true);
            getCarDetails(decodeURIComponent(carId))
                .then((res) => {
                    setCarDetails(res);
                    setCarDetailsLoading(false);
                })
                .catch((error) => {
                    setCarDetailsLoading(false);
                    setError(error);
                });
        }
    }

    function getRimSizeSelection(rimSizeAssignments: RimSizeAssignmentDTO[]) {
        let rimSizeSelection = rimSizeAssignments.map(rsa => rsa.front?.rimSize?.split('x')[1]);
        rimSizeSelection = Array.from(new Set(rimSizeSelection));
        return rimSizeSelection;
    }

    function filterRimColor(rimFront?: RimInformationDTO, rimRear?: RimInformationDTO) {
        setIsReloadingDetails(true);
        setRimId(rimFront?.rimExternalId);
        if (rimRear) {
            setRimRearId(rimRear?.rimExternalId);
        }
    }

    function filterRimSizeAssignments(sizesToFilter: RimSizeAssignmentDTO[], chosenRimSize: string) {
        const filtered = sizesToFilter.filter(rsa => rsa.front?.rimSize?.split('x')[1] === chosenRimSize);
        setFilteredRimSizeAssignments(filtered);
        setIsReloadingDetails(true);
        setRimId(filtered[0].front.rimIdCode);
        setRimRearId(filtered[0].rear?.rimIdCode);
        changeRimSize(filtered[0]);
    }

    function changeRimSize(size: RimSizeAssignmentDTO) {
        let rimDetailUrl = "/felgen/details/" + seoRimId.replace(rimId, size.front.rimIdCode) + (seoCarId ? "/" + encodeURIComponent(seoCarId) : "");
        const searchParams = new URLSearchParams(location.search);
        searchParams.set("rimSize", size.front?.rimSize?.split('x')[1]);
        if (size.rear?.rimIdCode && size.rear?.rimIdCode !== size.front?.rimIdCode) {
            searchParams.set("rimRearId", size.rear.rimIdCode);
        } else {
            searchParams.delete("rimRearId");
        }
        setSelectedRimSize(size);
        searchParams.delete('showAlternative');

        navigate({
            pathname: rimDetailUrl,
            search: searchParams.toString()
        }, {replace: true});
        setIsReloadingDetails(false);
    }

    function loadRimTyreAssignments(size: RimSizeAssignmentDTO) {
        setRimTyreAssignmentsLoading(true);
        const request: RimTyreAssignmentRequest = {
            carId,
            certificateBlockId: size.certificateBlockId,
            certificateId: size.certificateId,
            rimFrontId: size.front.rimIdCode,
            rimRearId: size.rear?.rimIdCode || size.front.rimIdCode
        };
        getRimTyreAssignment(request)
            .then(res => {
                setRimTyreAssignments(res);
                setRimTyreAssignmentsLoading(false);
            });
    }

    function goToTyreSearch() {
        window?.stop();
        const oldSearch = new URLSearchParams(location.search);
        const newSearch = new URLSearchParams({
            certificateId: selectedRimSize.certificateId.toString(),
            certificateBlockId: selectedRimSize.certificateBlockId.toString(),
            carColor: oldSearch.get("carColor"),
            selectedAmount: amount.toString(),
        });
        let tyre = rimTyreAssignments[0];
        if (selectedRimSize.abe && !tyre.abe) {
            let t = rimTyreAssignments.find((t) => t.abe);
            if (t) {
                tyre = t;
            }
        }
        navigate({
            pathname: `/reifen/${tyre.front.matchcode}/${tyre.rear?.matchcode || tyre.front.matchcode}/${encodeURIComponent(carId)}/${
                selectedRimSize.front.rimIdCode
            }/${selectedRimSize.rear?.rimIdCode || selectedRimSize.front.rimIdCode}`,
            search: newSearch.toString(),
        });
    }

    function addToShoppingCart() {
        window?.stop();
        setIsLoading(true);
        if (!rimDetails.rimRear) {
            addRimToShoppingCart(carId ? carId : null,
                rimDetails.rimFront, selectedRimSize?.front, amount, selectedRimSize?.certificateId,
                selectedRimSize?.certificateBlockId,
                rimDetails.mixedDataRimInformation.certificateInformation.pdfLocation)
                .then((res) => {
                    props.updateCartPreview();
                    setIsLoading(false);
                    setShowAddedToCartModal(true);
                })
                .catch((error) => {
                    setIsLoading(false);
                    setError(error);
                });
        } else {
            const frontReq = {
                carId: carId || null,
                rim: rimDetails.rimFront,
                rimSize: selectedRimSize?.front,
                amount: amount * 0.5,
                certificateId: selectedRimSize?.certificateId,
                certificateBlockId: selectedRimSize?.certificateBlockId,
                certificatePdfLink: rimDetails.mixedDataRimInformation.certificateInformation.pdfLocation,
                axle: Axle.FRONT
            };
            const rearReq = {
                carId: carId || null,
                rim: rimDetails.rimRear,
                rimSize: selectedRimSize?.rear,
                amount: amount * 0.5,
                certificateId: selectedRimSize?.certificateId,
                certificateBlockId: selectedRimSize?.certificateBlockId,
                certificatePdfLink: rimDetails.mixedDataRimInformation.certificateInformation.pdfLocation,
                axle: Axle.REAR
            };
            addMixedRimToShoppingCart([frontReq, rearReq]).then(() => {
                props.updateCartPreview();
                setIsLoading(false);
                setShowAddedToCartModal(true);
            }).catch((error) => {
                setError(error);
                setIsLoading(false);
                setIsFirstSearch(false);
            })
        }
    }

    function goBackToRimSearch(showAlternative?: boolean) {
        let path = "/felgen";
        const search = new URLSearchParams(location.search);
        if (carId) {
            path = `/felgenkonfigurator/${encodeURIComponent(carId)}`;
        }
        if (showAlternative) {
            search.append("showAlternative", showAlternative.toString());
        }
        navigate({
            pathname: path,
            search: search.toString()
        });
    }

    function resetCar() {
        let rimDetailUrl = "/felgen/details/" + (selectedRimSize ? selectedRimSize.front.rimIdCode : rimId);
        navigate(rimDetailUrl);
    }

    function getFilteredTyreRestrictionList(tyreList: RimTyreDTO[]): RimTyreDTO[] {
        return tyreList.filter(tyre => tyre.restrictions?.length > 0)
    }

    function convertRimColors() {
        return rimFrontColorAlternatives.map((rimColor, index) => {
            const setFallback = (e) => {
                e.target.src=rimColor.imagePerspectiveDTO.thumbnail;
            }
            const isSelected = (a: RimInformationDTO,b: RimInformationDTO ) => a.colour === b.colour && a.colorGroup === b.colorGroup
            return <div key={"rim-details-rim-color-" + index} className={"rim-color" + (isSelected(rimDetails.rimFront, rimColor) ? ' selected' : '')}
                onClick={() => filterRimColor(rimColor, rimDetails.rimRear ? rimRearColorAlternatives.find((rr) => isSelected(rr, rimColor)) : undefined)}>
                {rimColor?.imageFrontDTO?.thumbnail && <img src={rimColor.imageFrontDTO.thumbnail} alt={rimColor.colour} onError={setFallback}/> }
            </div>
        });
    }

    function convertRimSizes() {
        return rimSizes.map((rimSize, index) => {
            return <div
                key={"rim-details-rim-size-" + index}
                className={"rim-size" + (selectedRimSize ? (selectedRimSize.front?.rimSize?.split('x')[1] === rimSize ? " selected" : "") : "")}
                onClick={() => filterRimSizeAssignments(rimSizeAssignments, rimSize)}
            >
                {rimSize}
            </div>
        });
    }

    function toggleFullScreen() {
        setFullscreen(!fullscreen);
        dispatch({type: FullScreenModeActionType.SET, payload: {isEnabled: !fullscreen}});
    }

    return (
        <div id="rim-details-wrapper">
            <Helmet>
                {carId ? (
                    <title>{t("RIMS.HTML_TITLE_CONFIGURATOR")}</title>
                ) : (
                    <title>{t("RIMS.HTML_TITLE_SEARCH")}</title>
                )}
                <meta name="description" content={rimDetails?.rimFront?.displayName}/>
            </Helmet>
            {((isSmallScreen && orientation.type === Orientation.PORTRAIT) || !isSmallScreen) &&
                <TradeName noMarginBottom classes={'trade-name-car-view'}>
                <span className="d-none d-md-block">
                    {t("RIMS.SEARCH.TRADE_NAME", {tradeName: carDetails?.tradeName})}
                </span>
                    <span className="d-block d-md-none">{t("RIMS.DETAILS.TRADE_NAME")}</span>
                </TradeName>
            }

            {carId && rimId && (
                <div className={`container-fluid p-0 ${fullscreen ? 'h-100' : ''}`}>
                    <div className={`d-${mobileRimFullScreen ? 'flex' : 'none'} justify-content-center my-3`}>
                        <button className={"btn btn-primary"}
                                onClick={() => setMobileRimFullScreen(false)}>
                            {t('RIMS.SEARCH.SHOW_CAR_VIEW')}
                        </button>
                    </div>
                    <div className={`d-${!mobileRimFullScreen ? 'block' : 'none'}`}>
                        <CarView
                            carId={carId}
                            selectedRimId={rimId}
                            carTradeName={carDetails?.tradeName}
                            changeCar={() => resetCar()}
                            carDetails={carDetails}
                            fullscreen={fullscreen}
                            toggleFullscreen={toggleFullScreen}
                            rimDetails={rimDetails}
                            rimSizeAssignments={rimSizeAssignments}
                            selectedRimSize={selectedRimSize}
                            rimSizes={convertRimSizes()}
                            setSelectedRimSizeHandler={(rimSize) => filterRimSizeAssignments(rimSizeAssignments, rimSize)}
                            carImages={images}
                        />
                    </div>
                </div>
            )}
            {!carId && (
                isSmallScreen
                    ? <CarSelectionMobile isRimDetailPage={true}/>
                    : <CarSelection isRimDetailPage={true}/>
            )}
            {rimDetailsLoading && !isReloadingDetails && <LoadingDetails/>}
            {!isFirstSearch && !rimDetailsLoading && rimDetails && !rimDetails.rimFront && <ProductErrorMessage/>}
            {(!rimDetailsLoading && rimDetails && rimDetails.rimFront && !fullscreen) && (
                <div className={`container${isSmallScreen ? '-fluid' : ''} rim-details`}>
                    <div className="d-flex align-items-baseline my-3">
                        <i className="fas fa-long-arrow-alt-left"/>
                        <div className="ms-2 cursor" onClick={() => goBackToRimSearch()}>
                            {t("RIMS.DETAILS.BTN.GO_BACK")}
                        </div>
                    </div>
                    <div className="border-box-black rim-details-box">
                        <div className="box-title">
                            <div>
                                <span className="text-uppercase fw-bold">
                                    {rimDetails.rimFront.rimManufacturer} {" "}
                                </span>
                                {rimDetails.rimFront.rimType}
                            </div>
                        </div>
                        <div className="box-content rim-details-box-content">
                            <div className="row justify-content-end mb-4 mt-md-3">
                                <div className="col-12 col-md-4 col-lg-3 text-center px-xs-5 p-md-2 mb-3 mb-md-0">
                                    <div className="image-gallery-container">
                                        {images?.length > 0 &&
                                            <ImageGallery items={images}
                                                          ref={galleryRef}
                                                          onErrorImageURL="/no_images/felge.svg"
                                                          onClick={e => {
                                                              galleryRef.current.fullScreen();
                                                          }}
                                                          onScreenChange={(e) => setMobileRimFullScreen(e)}
                                                          showPlayButton={false}
                                                          showNav={false}
                                                          thumbnailPosition={isSmallScreen ? 'right' : 'bottom'}/>
                                        }
                                        {images?.length === 0 &&
                                            <ImageGallery items={noImage}
                                                          showPlayButton={false}
                                                          showNav={false}
                                                          showFullscreenButton={false}
                                                          showThumbnails={false}/>
                                        }
                                    </div>
                                </div>
                                <div className="col-12 col-md-8 col-lg-6">
                                    <h3 className="mb-3">{rimDetails.rimFront.rimManufacturer}<span
                                        className="text-capitalize"> {rimDetails.rimFront.rimType}</span></h3>
                                    <RimDetailsTable rimDetails={rimDetails}/>

                                    <div className="rim-color-wrapper">
                                        <label>{t('RIMS.DETAILS.TABLE.COLORGROUP')}</label>
                                        {convertRimColors()}
                                    </div>

                                    {rimSizes?.length > 0 && <div className="rim-size-wrapper">
                                        <label>{t('RIMS.DETAILS.TABLE.SIZE')}</label>
                                        {convertRimSizes()}
                                    </div>}

                                    <div className="my-0 my-md-3">
                                        {rimSizeAssignments?.length > 0 && (<div className="rim-size-selection">
                                            <MultilineDropdown
                                                    onChange={(size) => changeRimSize(size)}
                                                    options={filteredRimSizeAssignments}
                                                    name="rim-size-dd"
                                                    selectedValue={selectedRimSize}
                                                    label={!isSmallScreen ? t("RIMS.DETAILS.RIM_SIZE") + ":" : ""}
                                                    mode="RIM"
                                                />
                                                {rimSwitched && (
                                                    <small className="text-danger mt-n2">
                                                        {t("RIMS.DETAILS.SWITCHED")}
                                                    </small>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    {isSmallScreen &&
                                        <div className="amount-selection-mobile">
                                            <AmountInput
                                                amount={amount}
                                                handleAmountChange={(amount) => setAmount(amount)}
                                                onlyEvenNumbers={!!rimDetails.rimRear}
                                            />
                                        </div>
                                    }
                                    {selectedRimSize?.abe &&
                                        <div className="alert alert-info abe-info">
                                            {t("RIMS.DETAILS.ABE_TEXT")}
                                        </div>
                                    }
                                </div>
                                <div className="col-12 col-md-6 col-lg-3 mt-auto text-end">
                                    <div className="mb-1">
                                        <RimPrice rimDetails={rimDetails}
                                                  selectedRimSize={selectedRimSize}
                                                  amount={amount}/>
                                    </div>
                                    {!isSmallScreen &&
                                        <div className="d-flex align-items-end justify-content-end my-4">
                                            <div className="me-2">
                                                {t("RIMS.DETAILS.AMOUNT_OF_RIMS")}
                                            </div>
                                            <AmountInput
                                                amount={amount}
                                                handleAmountChange={(amount) => setAmount(amount)}
                                                onlyEvenNumbers={!!rimDetails.rimRear}
                                            />
                                        </div>
                                    }
                                    <Button
                                        variant="outline-primary"
                                        className="w-100 mt-3"
                                        onClick={() => addToShoppingCart()}
                                        disabled={isReloadingDetails || amount > availableQuantity || rimDetails?.rimFront?.priceAndStock == null}
                                    >
                                        {t("RIMS.DETAILS.BTN.ORDER_RIM")}
                                    </Button>
                                    <Button
                                        variant="green"
                                        className="w-100 mt-3 fw-normal"
                                        disabled={!carId || rimTyreAssignments?.length <= 0 || isReloadingDetails || amount > availableQuantity || rimDetails?.rimFront?.priceAndStock == null}
                                        onClick={() => goToTyreSearch()}
                                    >
                                        {t("RIMS.DETAILS.BTN.ORDER_WHEEL")}
                                    </Button>
                                    {
                                        amount > availableQuantity &&
                                        <p className={'alert-primary alert mt-3 text-start'}>
                                            {t("RIMS.DETAILS.AMOUNT_NOT_AVAILABLE")}
                                        </p>
                                    }
                                    {/*TODO: WCS-716
                                        <a className="btn btn-outline-primary mt-3" href="tel:+498002583354">
                                        <i className="fas fa-phone"/>{" "}
                                        {t("GLOBAL.HOTLINE")}
                                    </a>*/}
                                    <a className="btn btn-outline-primary mt-3" href="tel:+494264776930">
                                        <i className="fas fa-phone"/>{" "}
                                        {t("GLOBAL.HOTLINE")}
                                    </a>
                                    {!carId && (
                                        <div className="note-car-selection mt-2">
                                            {t("RIMS.DETAILS.PLEASE_SELECT_CAR_FIRST")}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <CertificateInformation
                                pdfLocation={rimDetails?.mixedDataRimInformation?.certificateInformation.pdfLocation}/>
                            {rimDetails?.mixedDataRimInformation?.certificateInformation?.rimRestrictions?.length > 0 &&
                                <RimRestrictions
                                    rimRestrictions={rimDetails?.mixedDataRimInformation.certificateInformation.rimRestrictions}/>
                            }
                            {rimDetails.mixedDataRimInformation?.certificateInformation?.brakeRestrictions?.length > 0 &&
                                <BrakeRestrictions
                                    brakeRestrictions={rimDetails.mixedDataRimInformation?.certificateInformation?.brakeRestrictions}/>
                            }
                            {rimDetails.mixedDataRimInformation?.tyreList?.length > 0 && getFilteredTyreRestrictionList(rimDetails.mixedDataRimInformation?.tyreList).length > 0 &&
                                <TyreRestrictions
                                    tyreList={getFilteredTyreRestrictionList(rimDetails.mixedDataRimInformation?.tyreList)}/>
                            }
                            <AdditionalInformation/>
                        </div>
                    </div>
                    <CustomModal open={showAddedToCartModal}>
                        <AddedToCartModal close={() => setShowAddedToCartModal(false)}/>
                    </CustomModal>
                </div>
            )}
            {!rimDetailsLoading && rimDetails && rimDetails.rimFront && !fullscreen && <ArrowBannerMountedWheel/>}
            <div className={`container${isSmallScreen ? '-fluid' : ''} mb-3`}
                 id="tyreSizeSelection">
                {selectedRimSize && !fullscreen && (
                    <RimTyreSizeTable
                        loading={rimTyreAssignmentsLoading}
                        rimFrontId={selectedRimSize?.front?.rimIdCode}
                        rimRearId={selectedRimSize?.rear?.rimIdCode}
                        carId={carId}
                        rimTyreAssignments={rimTyreAssignments}
                        selectedRimSize={selectedRimSize}
                        amount={amount}
                    />
                )}
            </div>
        </div>
    );
}

const mapDispatchToProps = {
    updateCartPreview
};
export default connect(null, mapDispatchToProps)(RimDetails);
