import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import facebook from "../../../assets/icons/svg/facebook.svg";
import whatsapp from "../../../assets/icons/svg/whatsapp.svg";
import twitter from "../../../assets/icons/svg/x-twitter.svg";
import {useMediaQuery} from "../../../helper/useMediaQuery";

interface Props {
    close?: Function;
    isSmallScreen: boolean;
}

enum SupportedPlatforms {
    WHATSAPP,
    FACEBOOK,
    TWITTER,
}

export default function CarViewShareButtons(props: Props) {
    const [t] = useTranslation();
    const [url, setUrl] = useState<string>();
    const isSmallScreen = useMediaQuery('(mac-width: 991.98px)')

    useEffect(() => {
        setUrl(window.location.href);
    }, [window.location.href]);

    function getButtonBar() {
        return (
            <div className={`${!props.isSmallScreen ? 'd-grid mt-4' : 'car-view-rim-page__button'} `}>
                <button className="btn w-100 btn-primary mt-2 fw-normal"
                        onClick={() => props.close()}>
                    {t('CAR_VIEW.CLOSE')}
                </button>
            </div>
        );
    }

    function shareUrl(targetPlatform: SupportedPlatforms) {

        if (!url || url.length == 0) {
            console.error("[Share Function] No url found, can't share anything!")
            return;
        }

        //ref: https://stackoverflow.com/questions/53514664/how-to-share-my-website-link-on-whatsapp-and-other-social-medias
        switch (targetPlatform) {
            case SupportedPlatforms.WHATSAPP:
                return isSmallScreen ? `whatsapp://send?text=${url}` : `https://api.whatsapp.com/send?text=${url.replace("%", "%25")}`;
            case SupportedPlatforms.FACEBOOK:
                return `https://www.facebook.com/sharer/sharer.php?u=${url}`;
            case SupportedPlatforms.TWITTER:
                return `https://twitter.com/intent/tweet?text=${url}`;
        }

    }

    const shareOptions: { label: string, icon: string, shareFunction: Function }[] = [
        {label: "WhatsApp", icon: whatsapp, shareFunction: () => shareUrl(SupportedPlatforms.WHATSAPP)},
        {label: "Facebook", icon: facebook, shareFunction: () => shareUrl(SupportedPlatforms.FACEBOOK)},
        {label: "Twitter", icon: twitter, shareFunction: () => shareUrl(SupportedPlatforms.TWITTER)}
    ]

    return (
        <div
            className={`${props.isSmallScreen ? 'd-flex flex-column justify-content-between h-100' : ''} car-view-share-buttons `}>
            <div className={`title-container mb-0`}>
                <h1 className={`title ${props.isSmallScreen ? 'mobile' : ''}`}>
                    {t('CAR_VIEW.SHARE')}
                </h1>
            </div>
            <div className="d-flex flex-column justify-content-center text-center">
                {shareOptions.map((option, index) => {
                    return <a key={index} target={"_blank"} href={option.shareFunction()} onClick={() => props.close()}
                              className={"share-button"}>
                        <img src={option.icon} className={"share-icon"}></img>
                    </a>
                })}
                {!props.isSmallScreen && getButtonBar()}
            </div>
            {props.isSmallScreen && getButtonBar()}
        </div>
    );
}
