import { useEffect, useRef, useState } from "react";

export interface ImageCache {
    [url: string]: HTMLImageElement;
}

export const useImageLoader = (imageUrls: string[]) => {
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);

    const imageCache = useRef<ImageCache>({});

    useEffect(() => {
        setLoaded(false);
        let isMounted = true;

        const loadImages = async () => {
            try {
                const promises = imageUrls.map((url) => {
                    // If image is already cached, resolve instantly
                    if (imageCache.current[url]) {
                        return Promise.resolve();
                    }

                    return new Promise((resolve, reject) => {
                        const img = new Image();
                        img.src = url;
                        img.onload = () => {
                            // Store image in cache
                            imageCache.current[url] = img;
                            resolve(img);
                        };
                        img.onerror = () => {
                            // resolve on error, so the img tag can try to fetch the file on its own
                            resolve(img);
                        };
                    });
                });

                await Promise.all(promises);


                if (isMounted) {
                    setLoaded(true);
                }
            } catch (e) {
                if (isMounted) {
                    setError(true);
                }
            }
        };

        loadImages();

        return () => {
            isMounted = false;
        };
    }, [imageUrls]);

    return { loaded, error, imageCache: imageCache.current }; // Return the cache
};
