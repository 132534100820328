import {RimDetailDTO} from "../../../models/rims/RimDetailDTO";
import React, {
    useRef,
    useState
} from "react";
import {useTranslation} from "react-i18next";
import {WishListDTO} from "../../../models/wishlist/WishlistDTO";
import {
    addArticleToWishlist,
    deleteArticleFromWishlist,
    getWishlist
} from "../../../redux-store/api/WishlistApi";
import ImageGallery from "react-image-gallery";
import RimPrice from "../../RimPrice/RimPrice";
import {RimSizeAssignmentDTO} from "../../../models/rims/RimSizeAssignmentDTO";
import {useMediaQuery} from "../../../helper/useMediaQuery";
import SeasonIcon, {SeasonEnum} from '../../SeasonIcon/SeasonIcon';
import {ReactComponent as FavoriteSvg} from '../../../assets/icons/svg/favorite.svg';

interface CarViewRimPageProps {
    rimId: string;
    rimDetails: RimDetailDTO;
    rimSizes: any[];
    selectedRimSize: RimSizeAssignmentDTO;
    rimSizeAssignments: RimSizeAssignmentDTO[];
    toggleFullScreenMode: Function;
    carImages?: any[];
}


export default function CarViewRimPage(props: CarViewRimPageProps) {
    const [t] = useTranslation();
    const [wishlistEntries, setWishlistEntries] = useState([]);
    const isFavorite: boolean = !!wishlistEntries?.find((entry) => entry.productId === props.rimId);

    const galleryRef = useRef(null);
    const noImage = [{original: '/no_images/felge.svg'}];
    let images = props.carImages ?? [];

    const getTabVersion = useMediaQuery("(max-height: 400px)");
    const [activeCategory, setActiveCategory] = useState<number>(0);
    const tabCategories: { key: string, catId: number }[] = [
        {key: "DETAILS", catId: 0},
        {key: "IMAGES", catId: 1},
    ];

    function loadWishlist(wishlistEntries?: WishListDTO[]) {
        if (wishlistEntries) {
            setWishlistEntries(wishlistEntries);
        } else {
            getWishlist().then((res) => {
                setWishlistEntries(res.wishlistEntries);
            });
        }
    }

    function changeFavorite(evt: React.MouseEvent<SVGSVGElement>) {
        evt.stopPropagation();

        const wishlistEntry = wishlistEntries?.find((entry) => entry.productId === props.rimId);

        if (wishlistEntry) {
            deleteArticleFromWishlist(wishlistEntry.id)
                .then((res) => {
                    loadWishlist();
                })
                .catch((error) => {
                    console.error("deleteArticleFromWishlist", error);
                });
        } else {
            addArticleToWishlist({
                description: props.rimDetails.rimFront.rimManufacturer,
                imageLink: props.rimDetails.rimFront.imagePerspectiveDTO.medium,
                itemType: "RIM",
                productId: props.rimDetails.rimFront.rimExternalId,
                wishlistId: 1,
            })
                .then((res) => {
                    loadWishlist(res.wishlistEntries);
                })
                .catch((error) => {
                    console.error("addArticleToWishlist", error);
                });
        }
    }

    function getPageHeader() {
        return <div className={"car-view-rim-page__header"}>
            <div className={"car-view-rim-page__header-text"}>
                <h3 className={`d-inline`}>{props.rimDetails.rimFront.rimManufacturer}</h3>
                <span>&nbsp;</span>
                <span className={"small"}>{props.rimDetails.rimFront.rimType}</span>
            </div>
            <div className={"car-view-rim-page__header-icons"}>
                {props.rimDetails.rimFront
                    && <>
                        {props.rimDetails.rimFront.winterproof
                            ? <div className="winterproof me-2">
                                <SeasonIcon season={SeasonEnum.WINTER} size={'1.5rem'}/>
                            </div>
                            : <div className={"me-2"}>&nbsp;</div>
                        }
                    </>}
                <FavoriteSvg height={'2rem'}
                             color={isFavorite ? 'var(--bs-primary)' : 'var(--bs-gray-300)'}
                             onClick={(evt) => changeFavorite(evt)}/>
            </div>
        </div>
    }

    function getRimSizes() {
        return <div className="rim-size-wrapper ">
            {props.rimSizes}
        </div>
    }


    if (props.rimDetails == undefined) {
        return <div className={"car-view-rim-page__header"}>
            <h5>{t("CAR_VIEW.ERROR")}</h5>
        </div>
    }

    if (getTabVersion) {
        return <div className={"car-view-rim-page "}>
            {getPageHeader()}
            <div className={"car-view-rim-page__category-panel "}>
                {tabCategories.map((category, index) =>
                    <button key={"car-view-tab-cat-" + index}
                            className={`col catgory-button fw-bold small  ${activeCategory == category.catId ? 'active' : ''}`}
                            onClick={() => setActiveCategory(category.catId)}>
                        {t("CAR_VIEW.TAB_CAT." + category.key).toUpperCase()}
                    </button>
                )}
            </div>

            <div className={"car-view-main-content"}>
                <div className={`d-${activeCategory == 0 ? 'block w-100 px-2' : 'none'}`}>
                    {getRimSizes()}
                    <RimPrice amount={4} rimDetails={props.rimDetails} selectedRimSize={props.selectedRimSize}
                              onlyPrice={true}/>
                </div>
                <div className={`d-${activeCategory == 1 ? 'block ' : 'none'}`}>
                    <img className={"car-view-rim-page__image"}
                         src={images?.length === 0 ? noImage : images[0].original}/>
                </div>
            </div>
        </div>
    }

    return <div className={"car-view-rim-page"}>
        {getPageHeader()}
        <div className={"car-view-main-content"}>
            <div className="car-view-image-gallery-container">
                <ImageGallery items={images?.length === 0 ? noImage : images}
                              ref={galleryRef}
                              onErrorImageURL="/no_images/felge.svg"
                              showPlayButton={false}
                              showNav={false}
                              thumbnailPosition='right'
                              showFullscreenButton={false}
                />
            </div>
            {getRimSizes()}
        </div>
        <RimPrice amount={4} rimDetails={props.rimDetails} selectedRimSize={props.selectedRimSize}
                  onlyPrice={true}/>
    </div>
}
