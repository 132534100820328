import React from "react";
import {useTranslation} from 'react-i18next';
import { RimSizeAssignmentDTO } from "../../models/rims/RimSizeAssignmentDTO";
import {RimTyreAssignmentDTO} from '../../models/rims/RimTyreAssignmentDTO';
import {useNavigate} from 'react-router-dom';
import {ReactComponent as CheckSvg} from '../../assets/icons/svg/check.svg';
import {ReactComponent as XSvg} from '../../assets/icons/svg/x.svg';

interface Props {
    rimTyreAssignments: RimTyreAssignmentDTO[];
    carId: string;
    rimFrontId: string;
    rimRearId: string;
    loading: boolean;
    selectedRimSize: RimSizeAssignmentDTO;
    amount: number;
}

export function RimTyreSizeTable(props: Props) {
    const {t} = useTranslation();
    const navigate = useNavigate();

    function selectTyre(tyre: RimTyreAssignmentDTO) {
        const oldSearch = new URLSearchParams(location.search);
        const newSearch = new URLSearchParams({
            certificateId: props?.selectedRimSize?.certificateId.toString(),
            certificateBlockId: props?.selectedRimSize?.certificateBlockId.toString(),
            carColor: oldSearch.get('carColor'),
            selectedAmount: props.amount?.toString()
        })
        navigate({
            pathname: `/reifen/${tyre.front.matchcode}/${tyre.rear?.matchcode || tyre.front.matchcode}/${encodeURIComponent(props.carId)}/${props?.selectedRimSize?.front.rimIdCode}/${props?.selectedRimSize?.rear?.rimIdCode || props?.selectedRimSize?.front.rimIdCode}`,
            search: newSearch.toString()
        });
    }

    return (
        <div className="border-box-black rim-tyre-size-selection">
            <div className="box-title">
                <div className="text-uppercase">{t('RIMS.RIM_TYRE_SIZE_DIALOG.TITLE')}</div>
            </div>
            <div className="box-content">
                {!props.loading &&
                <div className="px-3">
                    <div className="row header-row py-2">
                        <div className="col-4 col-md-3 px-1">
                            {t('RIMS.RIM_TYRE_SIZE_DIALOG.TYRE_SIZE')}
                        </div>
                        <div className="col-4 col-md-3 px-1 text-center">
                            {t('RIMS.RIM_TYRE_SIZE_DIALOG.ABE')}
                        </div>
                        <div className="col-4 col-md-3 px-1 text-center">
                            {t('RIMS.RIM_TYRE_SIZE_DIALOG.SNOW_CHAIN')}
                        </div>
                        <div className="d-none col-md-3 px-1"/>
                    </div>
                    {props.rimTyreAssignments.map((tyreSize, index) =>
                        <div className={`row content-row py-3 ${index === props.rimTyreAssignments.length -1 ? 'last' : ''}`}
                             key={tyreSize.front.matchcode + tyreSize.rear?.matchcode}>
                            <div className="col-4 col-md-3 px-1">
                                {!tyreSize.rear &&
                                <div>
                                    <span className="me-2">
                                        <img src="/car_front_rear_wheels.svg"
                                             className="axis small me-2"
                                             alt="car_front_rear_wheels.svg"
                                        />
                                    </span>
                                    <span className="no-wrap">{tyreSize.front.description}</span>
                                </div>
                                }
                                {tyreSize.rear &&
                                <div>
                                    <div>
                                        <span className="me-2">
                                            <img src="/car_front_wheel.svg"
                                                 className="axis small me-2"
                                                 alt="car_front_wheel.svg"
                                            />
                                        </span>
                                        <span className="no-wrap">{tyreSize.front.description}</span>
                                    </div>
                                    <div>
                                        <span className="me-2">
                                            <img src="/car_rear_wheel.svg"
                                                 className="axis small me-2"
                                                 alt="car_rear_wheel.svg"
                                            />
                                        </span>
                                        <span className="no-wrap">{tyreSize.rear.description}</span>
                                    </div>
                                </div>
                                }
                            </div>
                            <div className="col-4 col-md-3 px-1 text-center">
                                {tyreSize.abe && <CheckSvg height={'1rem'} color={'var(--bs-green)'}/>}
                                {!tyreSize.abe && <XSvg height={'1rem'} color={'var(--bs-red)'}/>}
                            </div>
                            <div className="col-4 col-md-3 px-1 text-center">
                                {tyreSize.abe && <CheckSvg height={'1rem'} color={'var(--bs-green)'}/>}
                                {!tyreSize.abe && <XSvg height={'1rem'} color={'var(--bs-red)'}/>}
                            </div>
                            <div className="col-12 col-md-3 px-1 text-end">
                                <button type="button" className="btn btn-green"
                                        onClick={() => selectTyre(tyreSize)}>
                                    {t('RIMS.RIM_TYRE_SIZE_DIALOG.SEARCH_TYRES')}
                                </button>
                            </div>
                        </div>
                    )
                    }
                    {props.rimTyreAssignments?.length <= 0 && <div className="row content-row py-3 last">
                        <div className="col-12 text-center">
                            {t('RIMS.RIM_TYRE_SIZE_DIALOG.NO_TYRE_SIZES')}
                        </div>
                    </div>}
                </div>
                }
                {props.loading &&
                    <div className="loading"/>
                }
            </div>
        </div>
    );
}

