import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {ReactComponent as CheckSvg} from '../../../assets/icons/svg/check.svg';

interface Props {
    colors: Array<{ colorId: string, colorPlateImageUrl: string }>
    selectedColorId: string;
    colorChanged?: Function;
    isSmallScreen: boolean;
}

export default function CarViewColors(props: Props) {
    const [t] = useTranslation();
    const [currentColor, setCurrentColor] = useState(props.selectedColorId);

    function getButtonBar() {
        return (
            <div className={`${!props.isSmallScreen ? 'd-grid mt-4' : 'car-view-rim-page__button'} `}>
                <button className="btn w-100 btn-primary mt-2 fw-normal"
                        onClick={() => props.colorChanged(currentColor)}>
                    {t('CAR_VIEW.CLOSE')}
                </button>
            </div>
        );
    }

    return (
        <div className={`${props.isSmallScreen ? 'd-flex flex-column justify-content-between h-100' : ''} `}>
            <div className={`title-container mb-0`}>
                <h1 className={`title ${props.isSmallScreen ? 'mobile' : ''}`}>
                    {t('CAR_VIEW.COLOR')}
                </h1>
            </div>
            <div className="p-3">
                <div className="colors">
                    {props.colors.map((color) => (
                        <div key={color.colorId} onClick={() => {
                            setCurrentColor(color.colorId);
                            props.colorChanged(color.colorId, true);
                        }}
                             className={"color" + (currentColor === color.colorId ? " current" : "")}>
                            {currentColor === color.colorId &&
                                <div className="selected">
                                  <CheckSvg height={'1rem'}/>
                                </div>
                            }
                            <img className="img img-fluid"
                                 src={'/api/bmf/image/de/colorPlate/' + color.colorId}
                                 alt={color.colorPlateImageUrl}
                            />
                        </div>
                    ))}
                </div>
                {!props.isSmallScreen && getButtonBar()}
            </div>
            {props.isSmallScreen && getButtonBar()}
        </div>
    );
}
