import React from "react";
import { useTranslation } from "react-i18next";

import {TyreEULabelDTO} from "../../models/tyres/TyreDTO";

import {ReactComponent as noiseClassASvg} from '../../assets/icons/svg/eu-noise-class-a.svg';
import {ReactComponent as noiseClassBSvg} from '../../assets/icons/svg/eu-noise-class-b.svg';
import {ReactComponent as noiseClassCSvg} from '../../assets/icons/svg/eu-noise-class-c.svg';
import {ReactComponent as rollingResistanceSvg} from '../../assets/icons/svg/eu-rolling-resistance.svg';
import {ReactComponent as wetGripSvg} from '../../assets/icons/svg/eu-wet-grip.svg';

import {
    EuLabelNoiseClassColor,
    EuLabelRollingResistanceColor,
    EuLabelWetGripColor
} from '../../models/shared/Enums';

interface Props {
    tyreEULabel: TyreEULabelDTO;
    iconSize?: string;
    hideLabelColor?: boolean;
    showEprel?: boolean;
}

function TyreEuLabel({tyreEULabel, iconSize, hideLabelColor, showEprel}: Props) {
    const [t] = useTranslation();

    function getLabel(color, src: any, infos: string, index: number){
        const Icon = src;
        return <div className="col d-flex align-items-center gap-2 no-wrap" key={"list-view-eu-label-" + index + Math.random()}>
            <Icon height={iconSize ?? '1rem'} color={'dark'}/>
            {hideLabelColor && <span className="ms-2" style={{minWidth: 'max-content'}}> {infos} </span>}
            {!hideLabelColor && <div className={'eu-label'} style={{
                '--euLabelColor': color
            } as React.CSSProperties}>
                {infos}
            </div>}
        </div>
    }

    let noiseClassSvg;
    switch(tyreEULabel.noiseClass) {
        case 'A':
            noiseClassSvg = noiseClassASvg;
            break;
        case 'B':
        default:
            noiseClassSvg = noiseClassBSvg;
            break;
        case 'C':
            noiseClassSvg = noiseClassCSvg;
            break;
    }

    const labels: {color: string, src: string, infos: string}[]= [
        {color: EuLabelRollingResistanceColor[tyreEULabel.rollingResistance], src: rollingResistanceSvg, infos: tyreEULabel.rollingResistance},
        {color: EuLabelWetGripColor[tyreEULabel.wetGrip], src: wetGripSvg, infos: tyreEULabel.wetGrip},
        {color: EuLabelNoiseClassColor[tyreEULabel.noiseClass], src: noiseClassSvg, infos: tyreEULabel.noiseEmission + " dB"},
    ];

    return (<>
        <div className="eu-label-list mt-3 gap-2">
            {labels.map((label, index) => getLabel(label.color, label.src, label.infos, index))}
        </div>
        {showEprel && <a
            href={`https://eprel.ec.europa.eu/qr/${tyreEULabel?.eprelRegistrationId}`}
            target="_blank"
            rel="noreferrer"
            className={'d-block mt-2'}
        >
            <i className="fas fa-file"/> {t("TYRES.DETAILS.PRODUCT_INFORMATION")}
        </a>}
    </>);
}

export default TyreEuLabel;
