import React, {useEffect, useRef, useState} from "react";
import {useTranslation, withTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import {AnalyticsHelper} from "../../../analytics/AnalyticsHelper";
import CartPosition from "../../../components/CartPosition/CartPosition";
import CartPositionMobile from "../../../components/CartPositionMobile/CartPositionMobile";
import CustomModal from "../../../components/CustomModal/CustomModal";
import {CartDTO, PaymentMethod} from "../../../models/cart/CartDTO";
import {clearCart, sendOrder} from "../../../redux-store/api/OrderApi";
import {PaymentLinkModal} from "../Payment/PaymentLinkModal";
import {NumericFormat} from "react-number-format";
import CartPrice from "../../ShoppingCart/components/CartPrice/CartPrice";
import RedeemVoucher from "../../ShoppingCart/components/RedeemVoucher/RedeemVoucher";
import AcceptedPaymentMethods from "../../ShoppingCart/components/AcceptedPaymentMethods/AcceptedPaymentMethods";
import {PaypalIntegration} from "../../../components/PayPal/PaypalIntegration";
import {useMediaQuery} from "../../../helper/useMediaQuery";
import {updateRemark} from "../../../redux-store/api/ShoppingCartApi";
import {useDispatch, useSelector} from "react-redux";
import {selectorAllowedCookies} from "../../../redux-store/selectors/cookieSelectors";

interface Props {
    previousStep: Function;
    cart: CartDTO;
    updateCartHandler: Function;
}

function OrderSummary({cart, previousStep, updateCartHandler}: Props) {
    const [t] = useTranslation();
    const navigate = useNavigate();
    const [agb, setAgb] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [paymentLinkModal, setPaymentLinkModal] = useState({showModal: false, paymentLink: ""});
    const [cancellationPolicy, setCancellationPolicy] = useState(true);
    const isSmallScreen = useMediaQuery("(max-width: 991.98px)");
    const [remarkChanged, setRemarkChanged] = useState("");
    const isFirstRender = useRef(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isFirstRender.current) {
            const timeOutId = setTimeout(() => changeRemark(remarkChanged), 1000);
            return () => clearTimeout(timeOutId);
        }
        isFirstRender.current = true;
    }, [remarkChanged]);

    function goBackToPreviousStep() {
        previousStep();
    }

    function changeRemark(remark: string) {
        updateRemark(encodeURIComponent(remark)).then((cart) => updateCartHandler(cart));
    }

    function soluteConversionTracking(orderId: number | string, sumGross: number, factor: string = "" ) {
        const ttl = 1000*60*60*24*30;
        const soluteId= localStorage.getItem("soluteclid");

        if (!soluteId) {
            return;
        }

        const split = soluteId.split(" ", 2);

        if ( parseInt(split[0])+ttl > (new Date()).getTime()) {
            let url = "https://cmodul.solutenetwork.com/conversion";
            url += "?val=" + encodeURIComponent(sumGross);
            url += "&oid=" + encodeURIComponent(orderId);
            url += "&factor=" + encodeURIComponent(factor);
            url += "&url=" + encodeURIComponent(split[1]);
            let req = new XMLHttpRequest();
            req.open("GET", url);
            req.send();
        } else {
            localStorage.removeItem("soluteclid");
        }
    }

    function onOrder() {
        setIsSubmitting(true)
        AnalyticsHelper.trackBeginCheckoutCart(cart)

        sendOrder().then((res) => {
            if (res.successful) {
                clearCart().then(() => dispatch({
                    type: "PREVIEW_UPDATE",
                    payload: {itemCount: 0, sumGross: 0}
                }));

                if (res.paymentCreationModel?.paymentUrl) {
                    window.location.href = res.paymentCreationModel?.paymentUrl;
                    setPaymentLinkModal({showModal: true, paymentLink: res.paymentCreationModel?.paymentUrl})
                } else {
                    const searchParams = new URLSearchParams()
                    searchParams.append('orderId', res.orderId + '');
                    searchParams.append('paymentMethod', cart.paymentMethod);
                    navigate({
                        pathname: "/order-confirmation/success",
                        search: searchParams.toString(),
                    });
                    window.gtag('event', 'conversion', {
                        'send_to': 'AW-1065970570/mDGHCK36mmAQitel_AM',
                        'value': res.order?.sumGross,
                        'currency': 'EUR',
                        'transaction_id': res.orderId
                    });
                    AnalyticsHelper.trackPurchaseByOrder(res.order)
                    soluteConversionTracking(res.orderId, res.order?.sumGross);
                }
            } else {
                navigate("/order-confirmation/error");
            }
        }).finally(() => setIsSubmitting(false));
    }

    return (
        <div className="order-summary">
            <div className="row">
                <div className="d-flex align-items-baseline mb-3 ms-3" onClick={() => goBackToPreviousStep()}>
                    <i className="fas fa-long-arrow-alt-left"/>
                    <div className="ms-2 cursor">{t("CHECKOUT.ORDER_SUMMARY.BACK_TO_PAYMENT_OPTIONS")}</div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-lg-8">
                    <div className="panel">
                        <div className="heading">
                            {t("CHECKOUT.ORDER_SUMMARY.DETAILS")}
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-4 mb-3 mb-md-2">
                                <div className="order-details-box">
                                    <div className="header">
                                        <div>{t("CHECKOUT.ORDER_SUMMARY.INVOICE_ADDRESS")}</div>
                                    </div>
                                    <div className="content">
                                        {cart?.invoiceAddress && (
                                            <>
                                                <div>
                                                    {cart.invoiceAddress.name1} {cart.invoiceAddress.name2}
                                                </div>
                                                <div>{cart.invoiceAddress.street}</div>
                                                <div>
                                                    {cart.invoiceAddress.zipCode} {cart.invoiceAddress.city}
                                                </div>
                                                <div>{t("ENUMS.COUNTRY_CODES." + cart.invoiceAddress.country)}</div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 mb-3 mb-md-2">
                                <div className="order-details-box">
                                    <div className="header">
                                        <div>{t("CHECKOUT.ORDER_SUMMARY.DELIVERY_ADDRESS")}</div>
                                    </div>
                                    <div className="content">
                                        {cart?.deliveryAddress && (
                                            <>
                                                <div>
                                                    {cart.deliveryAddress.name1} {cart.deliveryAddress.name2}
                                                </div>
                                                <div>{cart.deliveryAddress.street}</div>
                                                <div>
                                                    {cart.deliveryAddress.zipCode} {cart.deliveryAddress.city}
                                                </div>
                                                <div>{t("ENUMS.COUNTRY_CODES." + cart.deliveryAddress.country)}</div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 mb-3 mb-md-2">
                                <div className="order-details-box">
                                    <div className="header">
                                        <div>{t("CHECKOUT.ORDER_SUMMARY.PAYMENT_METHOD")}</div>
                                    </div>
                                    <div className="content">
                                        {cart?.paymentMethod && (<>
                                                {t("ENUMS.PAYMENT_METHODS." + cart.paymentMethod)}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="panel">
                        <div className="heading">
                            {t("CART.TITLE")} <span
                            className="fw-normal">({cart?.positions?.length} {t("CART.POSITION_TYPE.ARTICLE")})</span>
                        </div>
                        <div className="order-positions">
                            {isSmallScreen ? <>
                                <div className="cart-mobile mt-4">
                                    {cart?.positions.map((position, index, arr) => (
                                        <div key={position.id}>
                                            <div className="panel-mobile">
                                                <div className="heading">
                                                    <div className="title">
                                                        <strong>{t("CART.POS")} {index + 1}</strong> {position.carDescription &&
                                                        <span><strong>:</strong> {position.carDescription}</span>}
                                                    </div>
                                                </div>
                                                <div className="p-3">
                                                    <CartPositionMobile position={position}
                                                                        showInputs={false}
                                                                        onlyEvenNumbers={false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </> : <>
                                {cart?.positions.map((position, index, arr) => (
                                    <div
                                        className={`py-3 ${(arr.length - 1 === index) && !cart.voucherCode ? "" : "position-row-border"}`}
                                        key={position.id}>
                                        <div className="row align-items-center">
                                            <div className="col-2 col-lg-2 col-xl-1">
                                                <div className="position-number">
                                                    {index + 1}
                                                </div>
                                            </div>
                                            <div className="col-10 col-lg-10 col-xl-11 position-heading">
                                                <strong>{t(`CART.POSITION_TYPE.${position.positionType}`)} </strong>
                                                {position.carDescription && (
                                                    <>
                                                        {t("CART.POSITION_FOR_CAR")}{" "}
                                                        {position.carDescription}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <CartPosition position={position} showInputs={false} onlyEvenNumbers={false}/>
                                    </div>
                                ))}
                            </>}

                            {cart?.voucherCode && !cart?.voucherWarning &&
                                <div className="py-3">
                                    <div className="row m-0 p-2 align-items-center">
                                        <div className="col-1">
                                        </div>
                                        <div className="col-11 position-heading">
                                            <strong>{t('CART.ITEM_TYPE.VOUCHER')}</strong>
                                        </div>
                                    </div>
                                    <div className="row m-0 p-2">
                                        <div className="col-3"></div>
                                        <div className="col-7">
                                            {t('CART.ITEM_TYPE.VOUCHER')} {`"${cart.voucherCode}"`}
                                        </div>
                                        <div className="col-2 voucher">
                                            <NumericFormat
                                                value={cart.discount}
                                                displayType="text"
                                                decimalSeparator=","
                                                decimalScale={2}
                                                fixedDecimalScale
                                                suffix="€"
                                                prefix="- "
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-4">
                    <div className="panel">
                        <RedeemVoucher updateCart={(cart) => updateCartHandler(cart)}
                                       cart={cart} isCartView={false}/>
                    </div>
                    {isSmallScreen && cart &&
                        <div className="panel">
                            <div className="heading">{t('CHECKOUT.ORDER_SUMMARY.ORDER_REMARKS')}</div>
                            <textarea className="w-100 fw-normal" name="remark"
                                      placeholder={t('CHECKOUT.ORDER_SUMMARY.ORDER_REMARKS_PLACEHOLDER')}
                                      onChange={(event) => setRemarkChanged(event.target.value)}></textarea>
                        </div>
                    }
                    <div className="panel">
                        <CartPrice sumNet={cart?.sumNet}
                                   sumGross={cart?.sumGross}
                                   vat={cart?.vat}
                                   discount={cart?.discount > 0 ? cart.discount : null}
                                   shipping={cart?.shipping > 0 ? cart.shipping : null}
                                   isCartView={false}
                        />
                        <div className="order-checkout mt-3">
                            <div className="row">
                                <div className="col-12 d-flex">
                                    <label className="custom-checkbox my-2">
                                        <input className="me-1" type="checkbox" name="agb"
                                               defaultChecked={agb}
                                               onChange={() => setAgb(!agb)}/>
                                        <span className="checkmark"/>
                                        <div>
                                            {t("CHECKOUT.ORDER_SUMMARY.YES_I_HAVE")}
                                            <a
                                                href="/AGB.pdf"
                                                title={t("CHECKOUT.ORDER_SUMMARY.AGBS")}
                                                target="_blank"
                                                className="highlighted mx-1"
                                                onClick={(e) => e.stopPropagation()}
                                            >
                                                {t("CHECKOUT.ORDER_SUMMARY.AGBS")}
                                            </a>
                                            {t("CHECKOUT.ORDER_SUMMARY.READ_AND_ACCEPT_AGB")}
                                        </div>
                                    </label>
                                </div>
                                <div className="col-12 d-flex">
                                    <label className="custom-checkbox my-2">
                                        <input
                                            className="me-1"
                                            type="checkbox"
                                            name="cancellationPolicy"
                                            defaultChecked={cancellationPolicy}
                                            onChange={() => setCancellationPolicy(!cancellationPolicy)}
                                        />
                                        <span className="checkmark"/>
                                        <div>
                                            {t("CHECKOUT.ORDER_SUMMARY.YES_I_HAVE")}
                                            <Link
                                                to="/widerruf"
                                                target="_blank"
                                                className="highlighted mx-1"
                                                onClick={(e) => e.stopPropagation()}
                                            >
                                                {t("CHECKOUT.ORDER_SUMMARY.CANCELLATION_POLICY")}
                                            </Link>
                                            {t("CHECKOUT.ORDER_SUMMARY.READ_AND_ACCEPT_CANCELLATION_POLICY")}
                                        </div>
                                    </label>
                                </div>
                                <div className="col-12 my-3 text-end">
                                    {cart?.paymentMethod === PaymentMethod.PAY_PAL ? (
                                        <PaypalIntegration canSubmit={agb && cancellationPolicy && !isSubmitting}
                                                           cart={cart}/>
                                    ) : (
                                        <button
                                            type="submit"
                                            className="btn btn-green fw-normal"
                                            disabled={!agb || !cancellationPolicy || isSubmitting}
                                            onClick={onOrder}
                                        >
                                            {t("CHECKOUT.ORDER_SUMMARY.ORDER_NOW")}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {!isSmallScreen &&
                        <div className="panel">
                            <div className="heading">{t('CHECKOUT.ORDER_SUMMARY.ORDER_REMARKS')}</div>
                            <textarea className="w-100 fw-normal" name="remark"
                                      placeholder={t('CHECKOUT.ORDER_SUMMARY.ORDER_REMARKS_PLACEHOLDER')}
                                      onChange={(event) => setRemarkChanged(event.target.value)}></textarea>
                        </div>
                    }
                    <div className="panel">
                        <AcceptedPaymentMethods/>
                    </div>
                </div>
            </div>


            {isSubmitting && <div className="block-user-interactions"></div>}

            <CustomModal open={paymentLinkModal.showModal}>
                <PaymentLinkModal
                    close={() => setPaymentLinkModal({showModal: false, paymentLink: ""})}
                    paymentLink={paymentLinkModal.paymentLink}
                    paymentMethod={cart?.paymentMethod}
                />
            </CustomModal>
        </div>
    );
}

export default OrderSummary;
