import React, {
    useEffect,
    useState
} from 'react';
import {useDispatch} from "react-redux";
import {Tooltip as ReactTooltip} from "react-tooltip";
import {Link} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import {WishListDTO} from "../../models/wishlist/WishlistDTO";
import {
    deleteArticleFromWishlist,
    getWishlist as getWishlistApi
} from '../../redux-store/api/WishlistApi';
import Image from '../../components/Image/Image';
import {
    decrement,
    update
} from "../../redux-store/reducers/wishlistCountReducer";
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import {ReactComponent as FavoriteSvg} from '../../assets/icons/svg/favorite.svg';

export default function FavoriteView() {
    const {t} = useTranslation();
    const [wishlistEntries, setWishlistEntries] = useState<WishListDTO[]>([]);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        getWishlist();
    }, []);

    function getWishlist() {
        setLoading(true);
        getWishlistApi().then((res) => {
            let {wishlistEntries} = res;
            dispatch(update(wishlistEntries?.length))
            setWishlistEntries(wishlistEntries);
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    function deleteFromWishlist(whislistId) {
        deleteArticleFromWishlist(whislistId).then((res) => {
            dispatch(decrement());
            getWishlist();
        }).catch(error => {
            console.error('deleteArticleFromWishlist', error);
        });
    }

    function getFavoriteCards() {
        return (
            <div className="favorite-cards row mt-3">
                {wishlistEntries?.map((entry) => {
                    let detailUrl;
                    let name;
                    switch (entry.itemType) {
                        case "RIM":
                            detailUrl = "/felgen/details/" + entry.productId;
                            name = entry.product.rimFront.rimType;
                            break;
                        case "TYRE":
                            detailUrl = "/reifen/details/" + entry.productId;
                            name = "";
                            break;
                        default:
                            console.error("unknown wishlistEntries.itemType");
                            break;
                    }
                    return (
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-20p mb-3" key={entry.productId}>
                            <div className="favorite-card d-flex flex-column">
                                <div className={"text-end"}>
                                    <div className="fav-icon">
                                        <FavoriteSvg height={'1.5rem'}
                                                     onClick={() => deleteFromWishlist(entry.id)}
                                                     color={'var(--bs-primary)'}
                                                     className={'cursor shadow-none'}
                                        />
                                        <ReactTooltip id="favorite" place="bottom">
                                        <span>
                                            {t(
                                                "FAVORITE.TOOLTIPS.REMOVE_FROM_FAVORITES"
                                            )}
                                        </span>
                                        </ReactTooltip>
                                    </div>
                                </div>
                                <div className={"d-flex justify-content-center"}>
                                    <Image
                                        className="img-fluid"
                                        src={entry.imageLink}
                                        fallback="/no_images/felge.svg"
                                        alt={entry.description}
                                    />
                                </div>


                                <div className="description">
                                    <h3 className="manufacturer">
                                        {entry.description}
                                    </h3>
                                    <div className="name">{name}</div>
                                </div>
                                <Link to={detailUrl}>
                                    <button className="btn btn-primary w-100">
                                        {t("GLOBAL.BTN.DETAILS")}
                                    </button>
                                </Link>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }

    return (
        <div id="favorite-view">
            <Breadcrumb breadcrumbs={[
                {
                    i18nKey: 'FAVORITE'
                }
            ]}/>
            <div className="container-fluid">
                {loading ? <div>
                    <div className="favorite-cards row mt-3">
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-20p mb-3">
                            <div className="favorite-card-loader"/>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-20p mb-3">
                            <div className="favorite-card-loader"/>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-20p mb-3">
                            <div className="favorite-card-loader"/>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-20p mb-3">
                            <div className="favorite-card-loader"/>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-20p mb-3">
                            <div className="favorite-card-loader"/>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-20p mb-3">
                            <div className="favorite-card-loader"/>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-20p mb-3">
                            <div className="favorite-card-loader"/>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-20p mb-3">
                            <div className="favorite-card-loader"/>
                        </div>
                    </div>
                </div> : <>
                    {wishlistEntries && wishlistEntries.length > 0 ? getFavoriteCards() :
                        <h3 className="my-3 text-center text-black">
                            {t("FAVORITE.EMPTY")}
                        </h3>
                    }
                </>}
            </div>
        </div>
    );
}
