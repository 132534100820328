import React from "react";
import SeasonIcon from "../../../../../components/SeasonIcon/SeasonIcon";
import Image from "../../../../../components/Image/Image";
import {Tooltip as ReactTooltip} from "react-tooltip";
import {useTranslation} from "react-i18next";
import RatePayInfo from "../../../../../components/RatePayInfo/RatePayInfo";
import {SubTyreCardProps} from "./TyreCard";
import {useNavigate} from "react-router-dom";

import TyreEuLabel from '../../../../../components/TyreEuLabel/TyreEuLabel';
import {ReactComponent as FavoriteSvg} from '../../../../../assets/icons/svg/favorite.svg';

export default function ListViewTyreCard(props: SubTyreCardProps) {
    const {t} = useTranslation();
    const navigate = useNavigate();

    enum TyreType {
        FRONT,
        REAR,
        ALL,
    }

    function getTyreData(tyreType: TyreType): { description: string, image: string, infos: any, tyreType: TyreType } {
        const targetTyre = tyreType == TyreType.REAR ? props.tyre.rearTyre : props.tyre;
        const targetImage = TyreType.REAR
            ? "rear_wheel"
            : TyreType.ALL ? "front_rear_wheels" : "front_wheel";

        return {
            description: `${targetTyre.width}/${targetTyre.section}R${targetTyre.diameter} ${targetTyre.loadIndex}${targetTyre.speedIndex}`,
            image: targetImage,
            infos: props.showTyreInfos(targetTyre, true, tyreType == TyreType.REAR),
            tyreType: tyreType
        }
    }

    function getTyreDescription(tyreData: { description: string, image: string, infos: any, tyreType: TyreType }) {
        return <div>
            <div className={`d-flex justify-content-end align-items-end`}>
                <img src={`/car_${tyreData.image}.svg`} className={"tcl-mobile__axis"}
                     alt={`car_${tyreData.image}.svg`}/>
                <span className={`small text-end`}>&nbsp;{tyreData.description}</span>
            </div>
            {getEULabel("column")}
            {tyreData.tyreType != TyreType.ALL &&
                <div className={"d-flex flex-row justify-content-end text-end align-items-end"}>
                    <span>{tyreData.infos}</span>
                </div>
            }
        </div>
    }

    const tyreEULabel = props.tyre.tyreEULabelDTO;

    function getEULabel(flexDirection: string = "row") {
        if (!props.tyre.tyreEULabelDTO) {
            return <></>
        }

        return <>
            {props.tyre.tyreEULabelDTO &&
                <div className="tyre-label">
                    <TyreEuLabel tyreEULabel={tyreEULabel} hideLabelColor/>
                </div>
            }
        </>
    }

    function getTyreHtmlDesktop(tyreType: TyreType, textBlock: boolean = false) {
        const tyreData = getTyreData(tyreType);

        return (
            <div className={`tcl-tyre-infos__wheel`}>
                <img src={`/car_${tyreData.image}.svg`} className="axis mb-2 me-2" alt={`car_${tyreData.image}.svg`}/>
                <div className={`${textBlock ? 'text-block' : ''}`}>{tyreData.description}</div>
            </div>
        )
    }

    function getInnerContentDesktop() {
        return (
            <>
                {props.tyre.rearTyre
                    ? <>
                        {getTyreHtmlDesktop(TyreType.FRONT, true)}
                        {getEULabel()}
                        <hr/>
                        {getTyreHtmlDesktop(TyreType.REAR, true)}
                        {getEULabel()}
                    </>
                    : <>
                        {getTyreHtmlDesktop(TyreType.ALL)}
                        {getEULabel()}
                    </>
                }
            </>
        )
    }

    function onClickHandler() {
        if (props.isCompleteWheelSearch) {
            props.chooseTyreForMountedWheel();
        }
        props.detailsClicked();

        let url = {
            pathname: `/reifen/details/${props.tyre.productId}`,
            search: new URLSearchParams(location.search).toString(),
        }
        navigate(url);
    }

    function getDesktopCard() {
        return (
            <div className={"tyre-card tyre-card-list my-1 tyre-card-wrapper"}>
                <div className="tyre-card-list__image-wrapper">
                    <Image className="tyre-card-list__image" src={props.tyre.imageDTO.medium} fallback={props.noImageUrl}/>
                </div>
                <div className={"tyre-card-list__icon-container"}>
                    <SeasonIcon className={"me-2"} season={props.tyre.season} size="1.5rem"/>
                    <div className={`fav-icon`}>
                        <FavoriteSvg height={'1.5rem'}
                                     color={props.isFavorite ? 'var(--bs-primary)' : 'var(--bs-gray-300)'}
                                     className={'cursor shadow-none'}
                                     style={{outline: 'none'}}
                                     data-tooltip-id={"favorite-" + props.tyre.productId}
                                     onClick={(evt) => props.changeFavorite(evt)}/>
                        <ReactTooltip id={"favorite-" + props.tyre.productId} place="bottom">
                            {props.isFavorite ? (
                                <span>{t("FAVORITE.TOOLTIPS.REMOVE_FROM_FAVORITES")}</span>
                            ) : (
                                <span>{t("FAVORITE.TOOLTIPS.ADD_TO_FAVORITES")}</span>
                            )}
                        </ReactTooltip>
                    </div>
                </div>

                <div className={"tyre-card-list__heading"}>
                    <h5 className={"m-0 text-dark"}>{props.tyre.brandName}</h5>
                    <p className={"m-0 small"}>{props.tyre.tread}</p>
                </div>
                <div className={`tyre-card-list__tyre-infos tcl-mixed-wheel-false small`}>
                    {getInnerContentDesktop()}
                </div>
                <div className={"tyre-card-list__price"}>
                    {props.showTyreInfos(props.tyre, true)}
                    <div className={"my-2"}/>
                    {props.tyre.rearTyre && props.showTyreInfos(props.tyre.rearTyre, true, true)}
                    <div className={"w-100 d-flex justify-content-end"}>
                        <RatePayInfo className={"small"}/>
                    </div>
                </div>
                <div className={"tyre-card-list__button-bar"}>
                    {props.showButton()}
                </div>
            </div>
        );
    }


    function getMobileCard() {
        return (
            <div className={"my-1 p-2 tyre-card-wrapper tcl-mobile"}>
                <div className={"tcl-mobile__main-content"}>
                    <div className={`tcl-mobile-main-content__tyre`}>
                        <SeasonIcon season={props.tyre.season} size="2rem"/>
                        <div className={`tcl-mobile-main-content-tyre__image p-2`}>
                            <Image className={"img"}
                                   src={props.tyre.imageDTO.medium}
                                   fallback={props.noImageUrl}/>
                        </div>

                        <div className={`fav-icon`}>
                            <FavoriteSvg height={'1.5rem'}
                                         color={props.isFavorite ? 'var(--bs-primary)' : 'var(--bs-gray-300)'}
                                         className={'cursor shadow-none'}
                                         style={{outline: 'none'}}
                                         data-tooltip-id={"favorite-" + props.tyre.productId}
                                         onClick={(evt) => props.changeFavorite(evt)}/>
                            <ReactTooltip id={"favorite-" + props.tyre.productId} place="bottom">
                                {props.isFavorite ? (
                                    <span>{t("FAVORITE.TOOLTIPS.REMOVE_FROM_FAVORITES")}</span>
                                ) : (
                                    <span>{t("FAVORITE.TOOLTIPS.ADD_TO_FAVORITES")}</span>
                                )}
                            </ReactTooltip>
                        </div>
                    </div>
                    <div className={"tcl-mobile-main-content__text-infos "}>
                        <div className={`tcl-mobile-main-content__heading`}>
                            <h3 className={`d-inline m-0 text-dark`}>{props.tyre.brandName}</h3>
                            <p className={"mb-0 small"}>{props.tyre.tread}</p>
                            {!props.tyre.rearTyre && getTyreDescription(getTyreData(TyreType.ALL))}
                            {props.tyre.rearTyre && <>
                                {getTyreDescription(getTyreData(TyreType.FRONT))}
                                <hr/>
                                {getTyreDescription(getTyreData(TyreType.REAR))}
                            </>}
                        </div>
                    </div>
                </div>
                {!props.tyre.rearTyre &&
                    <div className={"d-flex flex-row justify-content-between"}>
                        <div className={`small tcl-mobile__amount-text`}>
                            {t("TYRES.SEARCH." + (props.isMountedWheel ? 'FOR_AMOUNT_WHEELS' : 'FOR_AMOUNT_TYRES'), {amount: 4})}
                            {t("GLOBAL.INCL_VAT", {vatInPercent: 19})}
                        </div>
                        <div className={"d-inline"}>
                            {props.showTyreInfos(props.tyre, true, false, true)}
                        </div>
                    </div>}
                <div className={"tyre-card-list__button-bar"}>
                    {props.showButton()}
                </div>
            </div>
        );
    }


    return props.smallScreen ? getMobileCard() : getDesktopCard();
}
