import React from "react";
import {Variant} from 'react-bootstrap/types';

interface Props {
    className?: string;
    variant?: Variant;
}

function Alert({children, className, variant}: React.PropsWithChildren<Props>) {
    let icon: string;
    let color: string;

    switch(variant) {
        case 'info':
            icon = 'bi-info-circle-fill';
            color = 'primary'
            break;
        default:
            break;
    }
    return (<div className={'custom-alert ' + className}>
        <i className={'bi ' + icon + ' text-' + color}></i>
        <div>
            {children}
        </div>
    </div>);
}

export default Alert;
