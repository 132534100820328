import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { CompleteProfileModal } from "../../../components/CompleteProfileModal/CompleteProfileModal";
import CustomModal from "../../../components/CustomModal/CustomModal";
import { Markdown } from "../../../components/Markdown/Markdown";
import UserContext from "../../../context/UserContext";
import { UserContextType } from "../../../context/UserContextType";
import { updateCartPreview } from "../../../redux-store/actions/cartPreviewAction";
import { getStaticPage } from "../../../redux-store/api/StrapiApi";

function OrderConfirmation({updateCartPreview}: {updateCartPreview: Function}) {
    const {status} = useParams<{ status: string }>();
    const [confluenceText, setConfluenceText] = useState("");
    const {t} = useTranslation();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);

    const {loggedIn, user} = useContext(UserContext) as UserContextType;
    const [showCompleteProfileModal, setShowCompleteProfileModal] = useState<boolean>(false)

    useEffect(() => {
        if (status === 'success') {
            let paymentMethod = urlParams.get("paymentMethod")
            let confirmationPage = "order_confirmation" + (paymentMethod ? '_'+paymentMethod.toLowerCase() : '')
            getStaticPage(confirmationPage).then((res) => {
                setConfluenceText((res.Content || "").replace(/ORDER_ID/ig, urlParams.get("orderId")));
            });
            updateCartPreview()
        }
    }, [status]);

    useEffect(()=>{
        if(loggedIn && !user?.phoneNumber) {
            setShowCompleteProfileModal(true);
        } else {
            setShowCompleteProfileModal(false)
        }
    },[user])

    function showSuccessMessage() {
        if (confluenceText) {
            return <div className="info"><Markdown content={confluenceText} /></div>;
        } else {
            return <div className="info">{t("ORDER_CONFIRMATION.SUCCESS")}</div>;
        }
    }

    return (
        <div className="container order-confirmation-wrapper">
            {status === "success" &&
                <>{showSuccessMessage()}</>}
            {status === "error" && (
                <div className="order-confirmation-wrapper">
                    <div className="info">{t("ORDER_CONFIRMATION.ERROR")}</div>
                </div>
            )}
            <CustomModal open={showCompleteProfileModal}>
                <CompleteProfileModal close={()=> setShowCompleteProfileModal(false)}></CompleteProfileModal>
            </CustomModal>
        </div>
    );
}

const mapDispatchToProps = {
    updateCartPreview,
};
export default connect(null, mapDispatchToProps)(OrderConfirmation);
