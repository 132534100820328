import React from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {CarDetailDTO} from "../../../../models/car/CarDetailDTO";
import {RimDetailDTO} from "../../../../models/rims/RimDetailDTO";
import {useMediaQuery} from "../../../../helper/useMediaQuery";
import {ReactComponent as EditSvg} from '../../../../assets/icons/svg/edit.svg';

interface Props {
    carDetails: CarDetailDTO;
    rimDetails?: RimDetailDTO;
    isLoading?: boolean;
    carId?: string;
}

export default function CompleteWheelBreadcrumb({
                                                    carDetails,
                                                    rimDetails,
                                                    carId,
                                                    isLoading
                                                }: Props) {
    const [t] = useTranslation();
    const isSmallScreen = useMediaQuery('(max-width: 767.98px)');
    let selectedAmount = Number(new URLSearchParams(location.search).get("selectedAmount"));
    let carColor = new URLSearchParams(location.search).get("carColor");
    if(carColor === 'null') {
        carColor = undefined;
    }

    if(!rimDetails) {
        return <></>
    }

    return (<>
        {!isLoading && <div className="helmet-border py-2 px-3">
          <div className={`box-content complete-wheel-breadcrumb`}>
            <div className={'d-flex align-items-center'}>
              <h2>
                1
              </h2>
              <div className="breadcrumb-entry">
                <div className="title">
                    {t("TYRES.SEARCH.BREADCRUMB.CAR")}
                </div>
                <div className="content">
                    {carDetails?.tradeName}
                  <Link to={`/felgen/details/${rimDetails?.rimFront?.rimExternalId}`}>
                      <EditSvg height={'1rem'} className={'ms-1'}/>
                  </Link>
                </div>
              </div>
            </div>
            <div className={'d-flex align-items-center'}>
              <h2>
                2
              </h2>
              <div className="breadcrumb-entry mr-4">
                <div className="title">
                    {t("TYRES.SEARCH.BREADCRUMB.RIM", {count: selectedAmount})}
                </div>
                <div className="content">
                    {!rimDetails?.rimRear && <div>
                      <img src="/car_front_rear_wheels.svg"
                           className="axis small me-2"
                           alt="car_front_rear_wheels.svg"
                      />
                        {rimDetails?.rimFront.rimManufacturer} {rimDetails?.rimFront.rimType}, {rimDetails?.rimFront.width}x{rimDetails?.rimFront.size} ET{rimDetails?.rimFront.offset}
                      <Link
                        to={`/felgen/details/${rimDetails?.rimFront?.rimExternalId}${carId ? "/" + encodeURIComponent(carId) : ""}` + (carColor ? `?carColor=${carColor}` : '')}>
                          <EditSvg height={'1rem'} className={'ms-1'}/>
                      </Link>
                    </div>}
                    {rimDetails?.rimRear && <>
                      <div>
                        <img src="/car_front_wheel.svg"
                             className="axis small me-2"
                             alt="car_front_wheel.svg"
                        />
                          {rimDetails?.rimFront.rimManufacturer} {rimDetails?.rimFront.rimType} {rimDetails?.rimFront.width}x{rimDetails?.rimFront.size} ET{rimDetails?.rimFront.offset}
                        <Link
                          to={`/felgen/details/${rimDetails?.rimFront?.rimExternalId}${carId ? "/" + encodeURIComponent(carId) : ""}` + (carColor ? `?carColor=${carColor}` : '')}>
                            <EditSvg height={'1rem'} className={'ms-1'}/>
                        </Link>
                      </div>
                      <div>
                        <img src="/car_rear_wheel.svg"
                             className="axis small me-2"
                             alt="car_rear_wheel.svg"
                        />
                          {rimDetails?.rimRear.rimManufacturer} {rimDetails?.rimRear.rimType} {rimDetails?.rimRear.width}x{rimDetails?.rimRear.size} ET{rimDetails?.rimRear.offset}
                        <Link
                          to={`/felgen/details/${rimDetails?.rimRear.rimExternalId}${carId ? "/" + encodeURIComponent(carId) : ""}` + (carColor ? `?carColor=${carColor}` : '')}>
                            <EditSvg height={'1rem'} className={'ms-1'}/>
                        </Link>
                      </div>
                    </>}
                </div>
              </div>
            </div>
          </div>
        </div>}
        {isLoading && <div className="complete-wheel-breadcrumb loading"/>}
    </>);
}
