import React, { ReactEventHandler, useEffect, useRef, useState } from "react";
import { ImageCache } from "../../helper/useImageLoader";
import { usePrevious } from "../../helper/usePrevious";
import {Layer} from "../interfaces/rim-config.interface";

interface BmfImageProps {
    layer: Layer;
    index: number;
    imageUrl: string;
    image: HTMLImageElement;
    currentImage: number;
    carColor: string;
    lowering?: number;
    smallImage?: boolean;
}

export function BmfImage(props: BmfImageProps) {
    const {image, index, layer, currentImage, lowering, imageUrl} = props;
    const loweredOffset = layer.canBeLowered ? lowering || 0 : 0;

    return (
        <img
            height={!props.smallImage ? props.layer.dimensions[index].height : props.layer.dimensions[index].height * 0.5}
            width={!props.smallImage ? props.layer.dimensions[index].width : props.layer.dimensions[index].width * 0.5}
            style={{
                display: index === currentImage ? "block" : "none",
                zIndex: layer.zIndex,
                position: "absolute",
                left: layer.positions[index].x + "px",
                top: (layer.positions[index].y + loweredOffset) + "px",
                transition: layer.canBeLowered ? 'top 2s ease 0s' : ''
            }}
            key={"layer.index" + index}
            src={image ? image.src : imageUrl}
        />
    )
}
