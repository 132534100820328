import {useEffect, useRef, useState} from "react";
import {loadScript} from "../helper/loadScript";
import {loginGoogle} from "../redux-store/api";
import {SignInProps} from "../types/SignInProps";
import {useTranslation} from "react-i18next";
import googleIcon from "../assets/icons/svg/google.svg";

export const GoogleSignIn = ({ updateLoggedIn, width, className }: SignInProps) => {
    const [attachScript, setAttachScript] = useState<boolean>(false)
    const src = "https://accounts.google.com/gsi/client";
    const [t] = useTranslation();

    useEffect(() => {
        if (attachScript) {
            loadScript(src)
                .then(() => {
                    const id = window?.googleAuthClientId;
                    google.accounts.id.initialize({
                        client_id: id,
                        callback: handleCredentialResponse,
                    });

                    try {
                        //Makes sure the login prompt gets displayed if the one-tap cooldown timer is active
                        //https://developers.google.com/identity/gsi/web/guides/features?hl=de#exponential_cooldown
                        document.cookie = `g_state=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
                    } catch {}
                    google.accounts.id.prompt();
                })
            return () => {
                const scriptTag = document.body.querySelector(`script[src="${src}"]`);
                if (scriptTag) document.body.removeChild(scriptTag);
            };
        }

    }, [attachScript, setAttachScript]);


    function handleCredentialResponse(response) {
        loginGoogle(response.credential).then(updateLoggedIn);
    }

    function login() {
        setAttachScript(!attachScript);
    }

    return <button id={"google-signin"} onClick={login} className={className}>
        <img src={googleIcon} className={"google-icon"}></img>
        &nbsp;
        {t("LOGIN.GOOGLE")}
    </button>
};
