import React from "react";
import {RimDTO} from "../../models/rims/RimSearchResultDTO";
import {RimGroupSearchResultDTO, RimItemDTO} from "../../models/rims/RimDetailDTO";
import {AnalyticsHelper} from "../../analytics/AnalyticsHelper";
import {NumericFormat} from "react-number-format";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {IRimConfig} from "../../bmf3dImages/interfaces/rim-config.interface";
import {CarDetailDTO} from '../../models/car/CarDetailDTO';
import {ReactComponent as EditSvg} from '../../assets/icons/svg/edit.svg';

interface BoxedCarViewProps {
    selectedRimGroup: RimDTO;
    changeRimSize: Function;
    selectedRim?: RimItemDTO;
    carId?: string;
    rim?: RimGroupSearchResultDTO;
    use3d: boolean;
    hideColorSelect?: boolean;
    carColor?: string;
    lowering?: number;
    setCanBeLowered?: (canBeLowered: boolean) => void;
    imageLoaded: (data: IRimConfig) => void;
    carImageUrl?: string;
    carDetails: CarDetailDTO;
}

export default function BoxedCarView(props: BoxedCarViewProps) {

    let rimSizes: any[] = [];
    const {t} = useTranslation();

    for (const item of props.selectedRimGroup.items) {
        const rimSize = item.size;
        let isSelectedRimSize = props.selectedRim.size === rimSize;
        rimSizes.push(
            <div
                key={rimSize}
                className={"car-view-boxed__rim-size mt-lg-1 p-0 rim-size-" + rimSize + (isSelectedRimSize ? " selected" : "") + (props.selectedRimGroup.items.length > 1 ? " mx-1" : "")}
                onClick={(e) => selectRim(rimSize)}
            >
                {rimSize}
            </div>
        );
    }


    let rimDetailUrl = {
        pathname: "../felgen/details/" + props.selectedRim?.rimId + (props.carId ? "/" + encodeURIComponent(props.carId) : ""),
        search: new URLSearchParams(location.search).toString(),
    };

    function getPrice() {
        if (props.selectedRimGroup.items) {
            for (const item of props.selectedRimGroup.items) {
                if (item.size === props.selectedRim.size) {
                    let price = +item.retailPrice.replace(/^(-)|[^0-9.,]+/g, '').replace(",", ".") * 4;
                    return (
                        <NumericFormat
                            value={price}
                            displayType="text"
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale={true}
                            suffix="€"
                        />
                    );
                }
            }
            let price = +props.selectedRimGroup.items[0].retailPrice.replace(/^(-)|[^0-9.,]+/g, '').replace(",", ".") * 4;
            return (
                <NumericFormat
                    value={price}
                    displayType="text"
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale={true}
                    suffix="€"
                />);
        }
    }


    function selectRim(size: number) {
        let newSize = props.selectedRimGroup.items.find((item: RimItemDTO) => +item.size == size)

        if (newSize) {
            props.changeRimSize(newSize);
        } else {
            props.changeRimSize(props.selectedRimGroup.items?.[0]);
        }
    }

    function detailsClicked(evt) {
        if (!props.rim) {
            return;
        }

        evt.stopPropagation();
        AnalyticsHelper.trackRimClick(props.rim);
    }


    return (<div className={"car-view-boxed mb-3 px-3 d-flex flex-column justify-content-center "}>
        <h3 className={'py-3'}>{t('BOXVIEW.TITLE')}</h3>
        <Link className={"py-3"} to={rimDetailUrl}
              onClick={(evt) => detailsClicked(evt)}>
            <div
                className={"rim-image-layers d-flex justify-content-center align-content-center align-items-center"}
                id={"small-car-view"}>
                <img src={props.carImageUrl} className="img-fluid"/>
            </div>
        </Link>
        <div className={'fw-bold my-3'}>
            {props.carDetails?.tradeName} <Link className={"ms-1"} to={"/"}>
                <EditSvg height={'1rem'} className={'text-highlight'}/>
            </Link>
        </div>
    </div>)
}
