import { CheckoutOrdersV2 } from "@paypal/paypal-js/types/apis/orders";
import {
    CreateOrderActions,
    OnApproveActions,
    OnApproveData,
    OnShippingChangeActions,
    OnShippingChangeData
} from "@paypal/paypal-js/types/components/buttons";
import { PayPalButtons, PayPalMessages } from "@paypal/react-paypal-js";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AnalyticsHelper } from "../../analytics/AnalyticsHelper";
import { CartDTO, PaymentMethod } from "../../models/cart/CartDTO";
import { OrderResultDTO } from "../../models/checkout/OrderResultDTO";
import { clearCart, sendOrder, updateOrderAddress } from "../../redux-store/api/OrderApi";
import { LogLevel, logToServer } from "../../redux-store/api/ServerLoggingApi";
import { changePaymentMethod } from "../../redux-store/api/ShoppingCartApi";

interface PayPalButtonWrapperProps {
    canSubmit: boolean;
    cart: CartDTO;
}

export function PayPalButtonWrapper({ canSubmit, cart }: PayPalButtonWrapperProps) {
    const navigate = useNavigate();
    const [paypalOrderId, setPaypalOrderId] = useState<string>(null);
    const [paypalForceReRender, setPaypalForceReRender] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [t] = useTranslation();

    const beforeUnloadListener = useCallback((event) => {
        event.preventDefault();
        return (event.returnValue = t("CHECKOUT.PAYMENT_IN_PROGRESS"));
    }, []);

    function updatePaymentInProgress(val: boolean) {
        if (val) {
            addEventListener("beforeunload", beforeUnloadListener);
        } else {
            removeEventListener("beforeunload", beforeUnloadListener);
        }
    }

    function onPaypalApprove(data: OnApproveData, actions: OnApproveActions) {
        setIsSubmitting(true);
        return actions.order
            .capture()
            .then(function (res) {
                const searchParams = new URLSearchParams();
                const orderId = res.purchase_units?.[0].reference_id;
                searchParams.append("orderId", res.purchase_units?.[0].reference_id + "");
                searchParams.append("paymentMethod", PaymentMethod.PAY_PAL);

                navigate({
                    pathname: "/order-confirmation/success",
                    search: searchParams.toString(),
                });

                window.gtag("event", "conversion", {
                    send_to: "AW-1065970570/mDGHCK36mmAQitel_AM",
                    value: res.purchase_units?.[0].amount.value,
                    currency: res.purchase_units?.[0].amount.currency_code,
                    transaction_id: orderId,
                });
                AnalyticsHelper.trackPurchaseByOrder(window.orderData?.order);
                setIsSubmitting(false);
                try {
                    const shipping: CheckoutOrdersV2["components"]["schemas"]["shipping_detail"] = res.purchase_units?.[0]?.shipping;
                    clearCart();
                    updateOrderAddress(res.purchase_units?.[0].reference_id as any, {
                        email: res.payer.email_address,
                        phoneNumber: res.payer.phone?.phone_number?.national_number,
                        deliveryAddress: {
                            name1: shipping?.name?.full_name ? shipping?.name?.full_name : res.payer.name.given_name,
                            name2: shipping?.name?.full_name ? "" : res.payer.name.surname,
                            country: shipping?.address?.country_code,
                            zipCode: shipping?.address?.postal_code,
                            street: [shipping?.address?.address_line_1, shipping?.address?.address_line_2]
                                .filter((v) => !!v)
                                .join(" "),
                            city: shipping?.address?.admin_area_2,
                        },
                    });
                } catch (e) {
                    console.warn("Could not update address, will be done on server side when receiving webhook");
                }
            })
            .finally(() => {
                setIsSubmitting(false);
                updatePaymentInProgress(false);
            });
    }

    function createPaypalOrder(data, actions: CreateOrderActions) {
        setIsSubmitting(true);
        updatePaymentInProgress(true);
        if (paypalOrderId) {
            return Promise.resolve(paypalOrderId).finally(() => setIsSubmitting(false));
        } else {
            return changePaymentMethod(PaymentMethod.PAY_PAL)
                .then(() => sendOrder())
                .then((res) => {
                    window.orderData = res;
                    window.orderId = res.orderId;
                    window.currentSumGross = res?.order?.sumGross;
                    const shipping: CheckoutOrdersV2["components"]["schemas"]['shipping_detail'] = cart.deliveryAddress
                        ? {
                              type: "SHIPPING",
                              name: {
                                  full_name: cart.deliveryAddress
                                      ? cart.deliveryAddress?.name1 + " " + cart.deliveryAddress?.name2
                                      : null,
                              },
                              address: {
                                  country_code: cart.deliveryAddress?.country,
                                  postal_code: cart.deliveryAddress?.zipCode,
                                  address_line_1: cart.deliveryAddress?.street,
                                  address_line_2: "",
                                  admin_area_1: "",
                                  admin_area_2: cart.deliveryAddress?.city,
                              },
                          }
                        : {
                              type: "SHIPPING",
                          };
                    return actions.order.create({
                        intent: "CAPTURE",
                        purchase_units: [
                            {
                                reference_id: res.orderId + "",
                                custom_id: res.orderId + "",
                                amount: {
                                    currency_code: "EUR",
                                    value: res.order.sumGross + "",
                                },
                                shipping: shipping,
                            },
                        ],
                    });
                })
                .then((orderId) => {
                    setPaypalOrderId(orderId);
                    return orderId;
                })
                .finally(() => setIsSubmitting(false));
        }
    }

    function onPaypalError(error: Record<string, unknown>) {
        setPaypalForceReRender(paypalForceReRender + 1);
        updatePaymentInProgress(false);
        logToServer({
            name: "PaypalError",
            logLevel: LogLevel.WARN,
            customParameters: {
                source: "PAYPAL",
                error,
            },
        });
    }

    function onPaypalCancel() {
        setPaypalForceReRender(paypalForceReRender + 1);
        updatePaymentInProgress(false);
    }

    function onPaypalShippingChange(data: OnShippingChangeData, actions: OnShippingChangeActions | any) {
        return updateOrderAddress(window.orderId, {
            deliveryAddress: {
                country: data.shipping_address.country_code,
                zipCode: data.shipping_address.postal_code,
                city: data.shipping_address.city,
            },
        })
            .catch(
                catchLog("UPDATE_ORDER_ADDRESS", undefined, {
                    shipping_address: data?.shipping_address,
                })
            )
            .then((order) => {
                if (order?.order?.sumGross) {
                    window.orderData = order;
                    console.log("got new price: " + order?.order?.sumGross + " and old price " + window.currentSumGross);
                    if (order?.order?.sumGross !== window.currentSumGross) {
                        console.log("prices did change");
                        window.currentSumGross = order?.order?.sumGross;
                        const patchAction = {
                            op: "replace",
                            path: `/purchase_units/@reference_id=='${order.orderId}'/amount`,
                            value: { value: (+order.order.sumGross).toFixed(2), currency_code: "EUR" },
                        };
                        return actions.order.patch([patchAction]).catch(
                            catchLog("PAYPAL_PATCH", order, {
                                patchAction,
                                shipping_address: data?.shipping_address,
                            })
                        );
                    } else {
                        console.log("prices did NOT change -> resolve");
                        return actions.resolve();
                    }
                } else {
                    console.log("got no price from server -> reject");
                    return actions.reject().catch(catchLog("PAYPAL_REJECT", order));
                }
            });
    }

    function catchLog(source: string, order?: OrderResultDTO, additional?: any) {
        return (error) => {
            console.log(source, error);
            let customParameters: any = {
                source: source,
                updatedOrderId: window?.orderId,
                additional: additional,
            };
            if(order){
                customParameters = {
                    ...customParameters,
                    orderId: order?.orderId,
                    orderValue: order.order.sumGross
                };
            }
            if (additional) {
                customParameters = {
                    ...customParameters,
                    ...additional,
                };
            }
            logToServer({
                name: error?.name || "PaypalShippingChange",
                message: error?.message ||  source,
                stack: error?.stack,
                logLevel: LogLevel.WARN,
                customParameters,
            });
            throw error;
        };
    }
    
    return (
        <>
            <div className="paypal-button-wrapper">
                <PayPalButtons
                    className={"my-1"}
                    style={{ layout: "vertical", height: 38, label: "pay" }}
                    disabled={!canSubmit || isSubmitting}
                    fundingSource={"paypal"}
                    forceReRender={[cart?.sumGross, paypalForceReRender]}
                    createOrder={createPaypalOrder}
                    onApprove={onPaypalApprove}
                    onError={onPaypalError}
                    onCancel={onPaypalCancel}
                    onShippingChange={onPaypalShippingChange}
                />
                <PayPalButtons
                    className={"my-1"}
                    style={{ layout: "vertical", height: 38, color: "gold" }}
                    fundingSource={"paylater"}
                    disabled={!canSubmit || isSubmitting}
                    forceReRender={[cart?.sumGross, paypalForceReRender]}
                    createOrder={createPaypalOrder}
                    onApprove={onPaypalApprove}
                    onError={onPaypalError}
                    onCancel={onPaypalCancel}
                    onShippingChange={onPaypalShippingChange}
                />
                <PayPalButtons
                    className={"my-1"}
                    style={{ layout: "vertical", height: 38, color: "black" }}
                    fundingSource={"card"}
                    disabled={!canSubmit || isSubmitting}
                    forceReRender={[cart?.sumGross, paypalForceReRender]}
                    createOrder={createPaypalOrder}
                    onApprove={onPaypalApprove}
                    onError={onPaypalError}
                    onCancel={onPaypalCancel}
                    onShippingChange={onPaypalShippingChange}
                />
                <PayPalMessages
                    style={{
                        text: {
                            align: "center",
                        },
                    }}
                    amount={cart?.sumGross}
                    currency={"EUR"}
                    forceReRender={[cart?.sumGross]}
                    placement={"cart"}
                />
            </div>
        </>
    );
}
