import React from "react";

import {ReactComponent as SeasonAllSvg} from '../../assets/icons/svg/season-all.svg';
import {ReactComponent as SeasonSummerSvg} from '../../assets/icons/svg/season-summer.svg';
import {ReactComponent as SeasonWinterSvg} from '../../assets/icons/svg/season-winter.svg';

export enum SeasonEnum {
    ALL = 'ALL_SEASON',
    SUMMER = 'SUMMER',
    WINTER = 'WINTER'
}

interface SeasonIconProps {
    season: SeasonEnum;
    size: string;
    className?: string;
}

function SeasonIcon(props: SeasonIconProps) {

    switch(props.season) {
        case SeasonEnum.WINTER:
            return <SeasonWinterSvg height={props.size} color={'#4EACD6'} className={props.className}/>;
        case SeasonEnum.SUMMER:
            return <SeasonSummerSvg height={props.size} color={'#E19A00'} className={props.className}/>;
        default:
            // @ts-ignore
            return <SeasonAllSvg height={props.size} style={{'--primary': '#E19A00', '--secondary': '#4EACD6'}} className={props.className}/>;
    }
}

export default SeasonIcon;
