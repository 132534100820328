import React from "react";
import { useTranslation } from "react-i18next";

import {ReactComponent as BannerConfiguratorSvg} from '../../../assets/icons/svg/banner-3d.svg';
import {ReactComponent as BannerCompleteWheelSvg} from '../../../assets/icons/svg/banner-complete-wheels.svg';
import {ReactComponent as BannerProductSelectionSvg} from '../../../assets/icons/svg/banner-product-selection.svg';
import {ReactComponent as BannerFastShippingSvg} from '../../../assets/icons/svg/banner-fast-shipping.svg';

function Info() {
    const [t] = useTranslation();

    return (
        <div className="gradient-arrow-banner-box info-wrapper m-0">
            <div className="gradient-arrow-banner d-none d-xl-flex">
                <div className="pointer p0" />
                <div className="pointer p1" />
                <div className="pointer p2" />
                <div className="pointer p3" />
                <div className="pointer p4" />
            </div>
            <div className="text-overlay infos flex-column flex-xl-row">
                <div className="text-left-side">
                    <div className="h2 m-0">
                        {t("HOME.INFO.TITLE_1")} <br />
                        <span className="small ">{t("HOME.INFO.TITLE_2")}</span>
                    </div>
                </div>
                <div className="text-center-side flex-column flex-lg-row">
                    <div className="info px-4">
                        <BannerConfiguratorSvg width={'4rem'} height={'4rem'} className={'mb-2'}/>
                        <div className="h3 no-wrap ">{t("HOME.INFO.3D_CONFIGURATOR")}</div>
                        <b>{t("HOME.INFO.3D_CONFIGURATOR_INFO")}</b>
                    </div>
                    <div className="info px-4">
                        <BannerCompleteWheelSvg width={'4rem'} height={'4rem'} className={'mb-2'}/>
                        <div className="h3 no-wrap ">{t("HOME.INFO.MOUNTED_WHEELS")}</div>
                        <b>{t("HOME.INFO.MOUNTED_WHEELS_INFO")}</b>
                    </div>
                    <div className="info px-4">
                        <BannerProductSelectionSvg width={'4rem'} height={'4rem'} className={'mb-2'}/>
                        <div className="h3 no-wrap ">{t("HOME.INFO.PRODUCT_SELECTION")}</div>
                        <b>{t("HOME.INFO.PRODUCT_SELECTION_INFO")}</b>
                    </div>
                    <div className="info px-4">
                        <BannerFastShippingSvg width={'4rem'} height={'4rem'} className={'mb-2'}/>
                        <div className="h3 no-wrap ">{t("HOME.INFO.FREE_SHIPPING")}</div>
                        <b>{t("HOME.INFO.FREE_SHIPPING_INFO")}</b>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Info;
