import React, { useEffect } from "react";
import { ImageCache } from "../../helper/useImageLoader";
import { Layer } from "../interfaces/rim-config.interface";
import { BmfImage } from "./bmf-image";


interface BmfCarAngleProps {
    layers: Array<Layer>;
    angleIndex: number;
    currentImage: number;
    carColor: string;
    scale: number;
    lowering?:number;
    smallImage?: boolean;
    imageCache?: ImageCache
}

export const BmfCarAngle: React.FC<BmfCarAngleProps> = (props) => {
    const { angleIndex, layers, carColor, currentImage, scale, imageCache, lowering, smallImage } = props;
    let classNames: Array<string> = [];
    if (angleIndex === currentImage) {
        classNames.push('rim-image-layer');
    } else {
        classNames.push('d-none');
    }

    return (
        <div className={classNames.join(' ')} style={{ transform: `scale(${scale})` }}>
            {layers?.map((layer, layerIndex) => {
                const imageUrl = layer.images[angleIndex];
                return (
                    <BmfImage
                        key={"layer_" + layerIndex + "_image_" + angleIndex}
                        layer={layer}
                        index={angleIndex}
                        imageUrl={imageUrl}
                        image={imageCache[imageUrl]}
                        currentImage={currentImage}
                        carColor={carColor}
                        lowering={lowering}
                        smallImage={smallImage}
                    />
                );
            })}
        </div>
    );
};
