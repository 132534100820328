import React from "react";
import {useTranslation} from "react-i18next";
import { getStockColorFromDeliveryStatus, getStockColorFromQuantity } from "../../helper/Helper";
import { DeliveryStatus, StocksColor } from "../../models/shared/Enums";
import {ReactComponent as CheckSvg} from '../../assets/icons/svg/check.svg';
import {ReactComponent as XSvg} from '../../assets/icons/svg/x.svg';
import {ReactComponent as WarningSvg} from '../../assets/icons/svg/warning.svg';

interface Props {
    stock?: number;
    stockColor?: StocksColor;
    deliveryStatus?: DeliveryStatus
    className?: string;
}

export function StockInfo(props: Props) {
    const {t} = useTranslation();
    const stockColor = props.stockColor || getStockColorFromDeliveryStatus(props.deliveryStatus) || getStockColorFromQuantity(props.stock);

    const classes = `stock-info ${props.className}`;

    let Icon;
    let translationText = "";

    switch (stockColor) {
        case StocksColor.GREEN:
            Icon = CheckSvg;
            translationText = "STOCK_INFO.IN_STOCK";
            break;

        case StocksColor.ORANGE:
            Icon = WarningSvg;
            translationText = "STOCK_INFO.FEW_IN_STOCK";
            break;

        case StocksColor.RED:
            Icon = XSvg;
            translationText = "STOCK_INFO.NO_STOCK";
            break;

        default:
            return null;
    }

    return (
        <div className={classes}>
            <Icon height={'0.75rem'} color={'var(--bs-'+stockColor.toLowerCase()+')'} className={'me-1'}/>
            {t(translationText)}
        </div>
    );
}
