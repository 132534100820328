import React, {
    useEffect,
    useState
} from "react";
import Helmet from "react-helmet";
import {useTranslation} from "react-i18next";
import {
    useDispatch,
    useSelector
} from "react-redux";
import {
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";
import {Dispatch} from "redux";
import {
    BooleanParam,
    useQueryParam
} from "use-query-params";
import {AnalyticsHelper} from "../../../analytics/AnalyticsHelper";
import CarSelection from "../../../components/CarSelection/CarSelection";
import CarSelectionMobile from "../../../components/CarSelectionMobile/CarSelectionMobile";
import CarView from "../../../components/CarView/CarView";
import {FilterBar} from "../../../components/Filter/FilterBar";
import {LoadingAnimation} from "../../../components/LoadingAnimation/LoadingAnimation";
import {scrollToTopFn} from "../../../components/ScrollToTop";
import {findSelectedRim} from "../../../helper/Helper";
import {UrlFilterHelper} from "../../../helper/UrlFilterHelper";
import {UrlSearchParamsHelper} from "../../../helper/UrlSearchParamsHelper";
import {UrlSortHelper} from "../../../helper/UrlSortHelper";
import {useMediaQuery} from "../../../helper/useMediaQuery";
import {usePrevious} from "../../../helper/usePrevious";
import {CarDetailDTO} from "../../../models/car/CarDetailDTO";
import {RimItemDTO} from "../../../models/rims/RimDetailDTO";
import {RimDTO} from "../../../models/rims/RimSearchResultDTO";
import {SortName} from "../../../models/shared/Enums";
import {FilterInterface} from "../../../models/shared/FilterInterface";
import {
    initialSort,
    SearchSort
} from "../../../models/shared/SearchSort";
import {SortDirection} from "../../../models/shared/SortDirection";
import {WishListDTO} from "../../../models/wishlist/WishlistDTO";
import {getCarDetails} from "../../../redux-store/api/CarApi";
import {getRims} from "../../../redux-store/api/RimApi";
import {getWishlist} from "../../../redux-store/api/WishlistApi";
import {
    RimBrandAction,
    RimBrandActionType
} from "../../../redux-store/reducers/rimBrandReducer";
import {
    RimSearchAction,
    RimSearchActionType,
    RimSearchState
} from "../../../redux-store/reducers/RimSearchReducer";
import {RimSizeData} from "./RimResults/RimCard/RimCard";
import RimResults from "./RimResults/RimResults";
import RimSearchParams from "./RimSearchParams/RimSearchParams";
import {FullScreenModeActionType} from "../../../redux-store/reducers/FullScreenModeReducer";
import ReactPaginate from "react-paginate";
import {StrapiCategoryPage} from "../../../models/strapi/StrapiCategoryPage";
import {StrapiMetadataHelmet} from "../../../components/Strapi/StrapiMetadataHelmet";
import {StrapiImage} from "../../../components/Strapi/StrapiImage";
import {Markdown} from "../../../components/Markdown/Markdown";
import {getManufacturerPage} from "../../../redux-store/api/StrapiApi";
import Filter from '../../../components/Filter/Filter';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import {ReactComponent as EditSvg} from '../../../assets/icons/svg/edit.svg';

export default function RimSearch() {
    const [t] = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const {
        carId,
        brand
    } = useParams<{ carId: string; brand: string }>();
    const prevCarId = usePrevious(carId);
    const dispatch = useDispatch<Dispatch<RimSearchAction | RimBrandAction>>();
    const fullScreenDispatch = useDispatch();
    const searchState = useSelector<any, RimSearchState>((state) => state.rimSearch);
    const brandDetails = useSelector<any, StrapiCategoryPage>((state) => state.rimBrand);

    const [selectedRim, setSelectedRim] = useState<RimItemDTO>(initialSelectedRim());
    const [selectedRimGroup, setSelectedRimGroup] = useState(null);
    const [carDetails, setCarDetails] = useState<CarDetailDTO>(null);
    const [showAlternativeRims, setShowAlternativeRims] = useQueryParam("showAlternativeRims", BooleanParam);
    const [carDetailsLoading, setCarDetailsLoading] = useState(true);
    const [rimsLoading, setRimsLoading] = useState(true);
    const [wishlistEntries, setWishlistEntries] = useState([]);
    const [reload, setReload] = useState<boolean>(false);
    const isSmallScreen = useMediaQuery("(max-width: 991.98px)");
    const [isFirstSearch, setIsFirstSearch] = useState(true);

    const rimConfigPage = "/";

    function initialSelectedRim(): RimItemDTO {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.has("rimSize")) {
            return {
                size: +searchParams.get("rimSize"),
                rimId: searchParams.get("rimId")
            };
        }
        return null;
    }

    useEffect(() => {
        const rimSearchState = UrlSearchParamsHelper.getRimSearchState({
            rims: [],
            filters: UrlFilterHelper.getFilterFromLocation(location, []),
            offset: 0,
            limit: 16,
            totalCount: 0,
            selectedPage: UrlSearchParamsHelper.getPageFromLocation(location),
            width: null,
            diameter: null,
            design: null,
            hubBoreHole: null,
            rimOffset: null,
            holeCount: null,
            holeCircle: null,
            brand: brand,
            widths: [],
            diameters: [],
            designs: [],
            hubBoreHoles: [],
            rimOffsets: [],
            holeCounts: [],
            holeCircles: [],
            brands: [],
            sort: UrlSortHelper.getSortFromLocation(location, initialSort),
        }, location,);
        dispatch({
            type: RimSearchActionType.INIT,
            payload: rimSearchState,
        });
        setReload(true);
        if (rimSearchState.brand) {
            loadBrandDetails(rimSearchState.brand);
        }
        loadWishlist();
        scrollToTopFn(carId ? 'scroll-target' : 'trade-name-container');

        fullScreenDispatch({
            type: FullScreenModeActionType.SET,
            payload: {isEnabled: false}
        });
    }, []);

    useEffect(() => {
        const newSearchFilters = UrlFilterHelper.getFilterFromLocation(location, searchState.filters, false);
        if (!UrlFilterHelper.compareFilters(newSearchFilters, searchState.filters)) {
            dispatch({
                type: RimSearchActionType.CHANGE_FILTERS,
                payload: {
                    filters: newSearchFilters,
                },
            });
            setReload(true);
        }
    }, [location.search]);

    useEffect(() => {
        if ((carId && !prevCarId) || (!carId && prevCarId)) {
            setReload(true);
        }
    }, [location.pathname, carId, prevCarId]);

    useEffect(() => {
        if (carId) {
            loadCarDetails();
            if (isSmallScreen) {
                scrollToTopFn(carId ? 'scroll-target' : 'trade-name-container');
            }
        } else {
            setCarDetailsLoading(false);
        }
    }, [carId]);

    useEffect(() => {
        if (reload) {
            loadRims();
        }
    }, [searchState, carId, reload, searchState.sort]);

    function loadWishlist(wishlistEntries?: WishListDTO[]) {
        if (wishlistEntries) {
            setWishlistEntries(wishlistEntries);
        } else {
            getWishlist().then((res) => {
                setWishlistEntries(res.wishlistEntries);
            });
        }
    }

    function loadCarDetails() {
        setCarDetailsLoading(true);
        getCarDetails(decodeURIComponent(carId))
            .then((res) => {
                setCarDetails(res);
                setCarDetailsLoading(false);
            })
            .catch(() => {
                setCarDetailsLoading(false);
            });
    }

    function resetCar() {
        setCarDetails(null);
        navigate(rimConfigPage);
    }

    function loadBrandDetails(brandName?: string) {
        if ((brandName && brandName !== brandDetails?.nodeId) || searchState.brand !== brandDetails?.nodeId) {
            getManufacturerPage(brandName || searchState.brand).then((page) => {
                if (page) {
                    dispatch({
                        type: RimBrandActionType.SET_DATA,
                        payload: page
                    });
                }
            });
        }
    }

    function loadRims() {
        setRimsLoading(true);
        setReload(false);

        getRims({
            carId: carId ? carId.split("-")[0] : null,
            limit: searchState.limit,
            offset: searchState.offset,
            filters: searchState.filters,
            brand: searchState.brand,
            width: searchState.width,
            diameter: searchState.diameter,
            design: searchState.design,
            hubBoreHole: searchState.hubBoreHole,
            rimOffset: searchState.rimOffset,
            holeCount: searchState.holeCount,
            holeCircle: searchState.holeCircle,
            sort: searchState.sort,
        })
            .then((res) => {
                let selRim = null;
                AnalyticsHelper.trackRimImpressions(res.results);
                if (res.results?.length > 0) {
                    if (selectedRim?.rimId) {
                        selRim = findSelectedRim(res.results, selectedRim);
                    }
                    if (selRim === null) {
                        selRim = res.results?.[0];
                    }
                }
                setSelectedRim((selectedRim) => ({
                    ...selRim,
                    size: selectedRim?.size,
                }));
                setSelectedRimGroup(selRim);
                dispatch({
                    type: RimSearchActionType.UPDATE_RESULTS,
                    payload: {
                        rims: res.results || [],
                        totalCount: res.count,
                        filters: res.filters,
                        brands: res.brands,
                        widths: res.widths,
                        diameters: res.diameters,
                        designs: res.designs,
                        hubBoreHoles: res.hubBoreHoles,
                        rimOffsets: res.rimOffsets,
                        holeCounts: res.holeCounts,
                        holeCircles: res.holeCircles,
                    },
                });
                UrlFilterHelper.pushFiltersToHistory(location, navigate, res.filters, false);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setRimsLoading(false);
                setIsFirstSearch(false);
            });
    }

    function filterSelectionCallback(filters: FilterInterface[], disableReload: boolean = false) {
        dispatch({
            type: RimSearchActionType.CHANGE_FILTERS,
            payload: {
                filters,
            },
        });

        if (!disableReload) {
            setReload(true);
        }
    }

    function onPageChange(page) {
        dispatch({
            type: RimSearchActionType.CHANGE_PAGE,
            payload: {
                selectedPage: page.selected,
            },
        });
        setReload(true);
        const searchParams = new URLSearchParams(location.search);
        searchParams.set("page", page.selected);
        navigate({search: searchParams.toString()}, {replace: true});
        scrollToTopFn(carId ? 'scroll-target' : 'trade-name-container');
    }

    function rimSelectionCallback(selectedRim: RimItemDTO, rimGroup: RimDTO) {
        setSelectedRim(selectedRim);
        if (rimGroup) {
            setSelectedRimGroup(rimGroup);
        }
        const searchParams = new URLSearchParams(location.search);
        searchParams.set("rimSize", selectedRim?.size + "");
        searchParams.set("rimId", selectedRim?.rimId);
        if (searchState.brand) {
            searchParams.set("brand", searchState?.brand);
        }
        navigate({search: searchParams.toString()}, {replace: true});
    }

    function searchParamsCallback(search) {
        const previousBrand = searchState.brand;
        dispatch({
            type: RimSearchActionType.CHANGE_SEARCH_PARAMS,
            payload: {
                search,
            },
        });
        const searchParams = new URLSearchParams(location.search);
        searchParams.set("page", "0");
        navigate({search: searchParams.toString()}, {replace: true});
        scrollToTopFn(carId ? 'scroll-target' : 'trade-name-container');
        setReload(true);
        if (search.brand !== previousBrand) {
            loadBrandDetails(search.brand);
        }
    }

    function onSortChange(value: string) {
        let direction = value.endsWith("DESC") ? SortDirection.DESC : SortDirection.ASC;

        const sort: SearchSort = {
            direction: direction,
            name: value.split("_")[0] as SortName,
        };

        UrlSortHelper.pushSortToHistory(location, navigate, sort);
        dispatch({
            type: RimSearchActionType.CHANGE_SORT,
            payload: {
                sort: sort,
            },
        });
        setReload(true);
    }

    let rimSizeData: RimSizeData = {
        maxSize: -1,
        minSize: -1,
        colAmount: -1
    };
    if (searchState.rims) {
        if (searchState.rims) {
            rimSizeData.colAmount = searchState.rims.reduce((maxLength, rim) => Math.max(maxLength, rim.items?.length), 0,);

            rimSizeData.maxSize = searchState.rims.reduce((maxSize, rim) => {
                const itemSizes = rim.items.map((item) => item.size);
                const maxItemSize = Math.max(...itemSizes);
                return Math.max(maxSize, maxItemSize);
            }, 0);

            rimSizeData.minSize = searchState.rims.reduce((minSize, rim) => {
                const itemSizes = rim.items.map((item) => item.size);
                const minItemSize = Math.min(...itemSizes);
                return Math.min(minSize, minItemSize);
            }, Infinity);
        }
    }

    return (<>
        {carId && <div className={'scroll-target'}></div>}
        <div id="rim-search">
            {carId && carDetails && carDetails?.tradeName ? (<>
                <Helmet>
                    <title>{t("RIMS.HTML_TITLE_CONFIGURATOR")}</title>
                    <meta name="description" content={t("RIMS.HTML_TITLE_CONFIGURATOR")}/>
                </Helmet>
                <Breadcrumb breadcrumbs={[
                    {
                        title: carDetails?.tradeName,
                        link: rimConfigPage,
                        icon: EditSvg
                    }, {
                        i18nKey: 'RIM_OVERVIEW',
                    }
                ]}/>
            </>) : (<div className={"helmet-border"}>
                {brandDetails?.nodeId === searchState.brand && brandDetails?.seo ? (
                    <StrapiMetadataHelmet metadata={brandDetails.seo}/>) : (<>
                    <Helmet>
                        <title>{t("RIMS.HTML_TITLE_SEARCH")}</title>
                        <meta name="description" content={t("RIMS.HTML_TITLE_SEARCH")}/>
                    </Helmet>
                </>)}
                <Breadcrumb breadcrumbs={[{i18nKey: 'RIM_OVERVIEW',}]}/>
                <div className="container-fluid p-0">
                    {isSmallScreen ? (<CarSelectionMobile isRimDetailPage={false}/>) : (<CarSelection isRimDetailPage={false}/>)}
                </div>
            </div>)}
            <div className="container-fluid position-relative">
                {searchState.brand === brandDetails?.nodeId && (brandDetails?.seo?.shareImage || brandDetails?.title) && (
                    <div id="rim-brand" className="pt-3">
                        <div id="brand-banner">
                            {brandDetails?.seo?.shareImage ? (<StrapiImage
                                image={brandDetails?.seo?.shareImage}/>) : (<h1>{brandDetails?.title}</h1>)}
                        </div>
                        <div id="brand-content">
                            <Markdown content={brandDetails.teaser}/>
                        </div>
                    </div>)}
                {showAlternativeRims && (<div className="alternative-rims-info p-2">{t("RIMS.SEARCH.SHOW_ALTERNATIVE_RIMS_INFO")}</div>)}
                <div className="row mt-3 mx-0">
                    <div className="col-12 col-lg-3 px-lg-0 ">
                        {!carId && (<RimSearchParams
                            isLoading={rimsLoading}
                            searchParams={{
                                brand: searchState.brand,
                                width: searchState.width,
                                diameter: searchState.diameter,
                                design: searchState.design,
                                hubBoreHole: searchState.hubBoreHole,
                                rimOffset: searchState.rimOffset,
                                holeCount: searchState.holeCount,
                                holeCircle: searchState.holeCircle,
                            }}
                            searchParamsOptions={{
                                brands: searchState.brands,
                                widths: searchState.widths,
                                diameters: searchState.diameters,
                                designs: searchState.designs,
                                hubBoreHoles: searchState.hubBoreHoles,
                                rimOffsets: searchState.rimOffsets,
                                holeCounts: searchState.holeCounts,
                                holeCircles: searchState.holeCircles,
                            }}
                            searchParamsCallback={searchParamsCallback}
                            isSmallScreen={isSmallScreen}
                        />)}
                        {carId && selectedRim?.rimId && !isSmallScreen && (<div className={"car-view"}>
                            <LoadingAnimation isLoading={carDetailsLoading} style={{height: "30rem"}}>
                                <CarView
                                    carId={carId.split("-")[0]}
                                    carDetails={carDetails}
                                    selectedRimId={selectedRim?.rimId}
                                    selectedRimGroup={selectedRimGroup}
                                    carTradeName={carDetails?.tradeName}
                                    changeCar={() => resetCar()}
                                    changeRimSize={(item) => rimSelectionCallback(item, null)}
                                    boxView={true}
                                    selectedRim={selectedRim}
                                    rimGroupSearchResultDTO={searchState.rims?.find((x) => x.rimId == selectedRim?.rimId,)}
                                />
                            </LoadingAnimation>
                        </div>)}
                        {!isSmallScreen && <Filter
                            filters={searchState.filters}
                            filterSelectionCallback={filterSelectionCallback}
                            side
                        />}
                    </div>
                    <div className="col-12 col-lg-9 pe-lg-2">
                        {isSmallScreen && <FilterBar
                            filterSelectionCallback={filterSelectionCallback}
                            filters={searchState.filters}
                            sortChanged={(e) => onSortChange(e)}
                            sort={searchState.sort}
                            isLoading={rimsLoading}
                        ></FilterBar>}
                        <RimResults
                            rims={searchState.rims}
                            selectedRim={selectedRim}
                            carId={carId ? carId.split("-")[0] : ""}
                            carManufacturer={carDetails?.manufacturer}
                            carTradeName={carDetails?.tradeName}
                            totalCount={searchState.totalCount}
                            pageSize={searchState.limit}
                            isLoading={rimsLoading}
                            wishlistEntries={wishlistEntries}
                            rimSelectionCallback={rimSelectionCallback}
                            getWishlistCallback={loadWishlist}
                            sort={searchState.sort}
                            onSortChange={(e) => onSortChange(e)}
                            firstSearch={isFirstSearch}
                            filterSelectionCallback={filterSelectionCallback}
                            filters={searchState.filters}
                        />
                        {searchState.totalCount > 0 && searchState.limit && !rimsLoading && (<ReactPaginate
                            previousLabel={<i className="fas fa-angle-left"/>}
                            nextLabel={<i className="fas fa-angle-right"/>}
                            breakLabel="..."
                            breakClassName="break"
                            forcePage={searchState.selectedPage}
                            pageCount={Math.ceil(searchState.totalCount / searchState.limit)}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={2}
                            onPageChange={onPageChange}
                            containerClassName="pagination"
                            activeClassName="active"
                            activeLinkClassName="active-link"
                            pageClassName="page"
                            pageLinkClassName="page-link"
                        />)}
                    </div>
                </div>
            </div>
        </div>
    </>);
}

RimSearch.serverFetch = (params, url) => {
    const rimSearchState = UrlSearchParamsHelper.getRimSearchState({
        rims: [],
        filters: UrlFilterHelper.getFilterFromLocation(url, []),
        offset: 0,
        limit: 16,
        totalCount: 0,
        selectedPage: 0,
        width: null,
        diameter: null,
        design: null,
        hubBoreHole: null,
        rimOffset: null,
        holeCount: null,
        holeCircle: null,
        brand: params.brand,
        widths: [],
        diameters: [],
        designs: [],
        hubBoreHoles: [],
        rimOffsets: [],
        holeCounts: [],
        holeCircles: [],
        brands: [],
        sort: initialSort,
    }, url,);
    return getManufacturerPage(rimSearchState.brand).then((page) => {
        return [
            {
                type: RimBrandActionType.SET_DATA,
                payload: page
            }, {
                type: RimSearchActionType.INIT,
                payload: rimSearchState,
            },
        ];
    });
};

