import classNames from "classnames";
import {produce} from "immer";
import React, {
    useEffect,
    useState
} from "react";
import {useTranslation} from "react-i18next";
import {
    useLocation,
    useNavigate
} from "react-router-dom";
import {UrlFilterHelper} from "../../helper/UrlFilterHelper";
import {useMediaQuery} from "../../helper/useMediaQuery";
import {FilterInterface} from "../../models/shared/FilterInterface";
import {SearchSort} from "../../models/shared/SearchSort";
import Input, {InputChanged} from "../Input/Input";
import {SortOptions} from "../Input/SortDropdown";
import {LoadingAnimation} from "../LoadingAnimation/LoadingAnimation";
import FilterModal from "./FilterModal";
import {FilterSelected} from './FilterSelected';

interface FilterBarProps {
    filters: FilterInterface[];
    filterSelectionCallback: (filters: FilterInterface[], disableReload?: boolean) => void;
    sortChanged: (value: string) => void;
    sort: SearchSort;
    isLoading: boolean;
}

export function FilterBar({ filters, filterSelectionCallback, sort, sortChanged, isLoading }: FilterBarProps) {
    const navigate = useNavigate();
    const location = useLocation();
    const [t] = useTranslation();
    const [openedFilter, setOpenedFilter] = useState<string>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [activeFilters, setActiveFilters] = useState<FilterInterface[]>(filters);

    useEffect(() => {
        setActiveFilters(filters);
    }, [filters]);

    const isSmallScreen = useMediaQuery("(max-width: 991.98px)");
    const triggerSortChange: InputChanged = (values) => {
        if (values?.length > 0) {
            sortChanged(values[0]);
        }
        setOpenedFilter(undefined);
    };

    function resetFilter() {
        const filters = produce(activeFilters, (draft) => {
            draft.forEach((filter) => {
                filter.selectedValues = [];
            });
        });
        setActiveFilters(filters);
        filterSelectionCallback(filters, false);
        UrlFilterHelper.pushFiltersToHistory(location, navigate, filters);
        setOpenedFilter(undefined);
    }

    return (
        <div className="filter-bar">
            <LoadingAnimation style={{ height: "90px" }} isLoading={isLoading}>
                <div className="row g-0 mb-3">
                    {(isSmallScreen || activeFilters?.length > 4) && (
                        <div className="col-6 col-lg dropdown">
                            <button
                                className="btn btn-light w-100"
                                onClick={() => {
                                    setShowModal(true);
                                    setOpenedFilter(undefined);
                                }}
                            >
                                {t(`FILTER.${isSmallScreen ? "SHOW_FILTERS" : "MORE_FILTER"}`)}
                            </button>
                        </div>
                    )}
                    <div className="col-6 d-lg-none dropdown sort">
                        <button
                            className="btn btn-light dropdown-toggle w-100"
                            onClick={() => setOpenedFilter("SORT" === openedFilter ? undefined : "SORT")}
                        >
                            {t(`FILTER.SORT`)}
                        </button>
                        <div className={classNames("dropdown-menu p-2", { show: "SORT" === openedFilter })}>
                            <Input
                                type={"RADIO_BUTTON"}
                                value={sort ? [sort.name + "_" + sort.direction] : []}
                                options={SortOptions.map((s) => s.name + "_" + s.direction)}
                                onChange={triggerSortChange}
                                translateValue={true}
                                translatePrefix={"INPUT.SORT_OPTIONS"}
                            />
                        </div>
                    </div>
                </div>

                <FilterSelected filters={activeFilters} filterSelectionCallback={filterSelectionCallback}/>

                <div >
                    {activeFilters && [].concat(...activeFilters?.filter(f=> !(f.key === 'MIN_QUANTITY' && f.selectedValues?.[0] === '0')).map(f => f.selectedValues || [])).length > 0 &&
                        <button onClick={()=>resetFilter()} className="ps-0 filter-reset-text btn btn-link">{t("FILTER.RESET_ALL")}</button>
                    }
                </div>
            </LoadingAnimation>
            {showModal && (
                <FilterModal
                    filterSearchState={activeFilters}
                    filterSelectionCallback={filterSelectionCallback}
                    onClose={() => setShowModal(false)}
                />
            )}
        </div>
    );
}
