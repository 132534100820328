import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Markdown } from "../../../../components/Markdown/Markdown";
import { getStaticPage } from "../../../../redux-store/api/StrapiApi";

function AdditionalInformation() {
    const [t] = useTranslation();
    const [content, setContent] = useState<string>();

    useEffect(() => {
        getStaticPage("riminformation").then((res) => {
            setContent(res?.Content);
        });
    }, [])

    return (
        <div className="expandable-information">
            <button className="btn btn-collapsible" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseAdditionalInfo" aria-expanded="false"
                    aria-controls="collapseAdditionalInfo">
                <div className="d-flex justify-content-between">
                    <div>{t("RIMS.DETAILS.ADDITIONAL_INFORMATION")}</div>
                    <div><i className="fas fa-chevron-down"/></div>
                </div>
            </button>
            <div className="collapse" id="collapseAdditionalInfo">
                <div className="content">
                    <Markdown content={content} />
                </div>
            </div>
        </div>
    )
}

export default AdditionalInformation;
