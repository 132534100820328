import React from "react";
import {useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";
import {AnalyticsHelper} from "../../../../../analytics/AnalyticsHelper";
import {
    RimGroupSearchResultDTO,
    RimItemDTO
} from "../../../../../models/rims/RimDetailDTO";
import {DeliveryStatus} from "../../../../../models/shared/Enums";
import {WishListDTO} from "../../../../../models/wishlist/WishlistDTO";
import {
    addArticleToWishlist,
    deleteArticleFromWishlist
} from "../../../../../redux-store/api/WishlistApi";
import {
    decrement,
    update
} from "../../../../../redux-store/reducers/wishlistCountReducer";
import ListViewRimCard from "./ListViewRimCard";
import GridViewRimCard from "./GridViewRimCard";
import {useMediaQuery} from "../../../../../helper/useMediaQuery";
import {useTranslation} from "react-i18next";
import Tooltip from "rc-tooltip";
import {StringHelper} from "../../../../../helper/StringHelper";

export interface RimSizeData {
    maxSize: number;
    minSize: number;
    colAmount: number;
}

export interface RimViewCardProps {
    rim: RimGroupSearchResultDTO;
    carId?: string;
    rimSelectionCallback: Function;
    getWishlistCallback: Function;
    wishlistEntries?: WishListDTO[];
    selectedRim?: RimItemDTO;

    selectRim: Function;
    changeFavorite: Function;
    getPrice: Function;
    getRecommendedRetailPrice: Function;
    detailsClicked: Function;
    rimSizesList: any[];
    isFavorite: boolean;
    rimImageSrc: string;
    isSelected?: RimItemDTO;
    rimDetailUrl: { pathname: string, search: string };
    isSmallScreen?: boolean;
    deliveryStatus?: DeliveryStatus;
}


interface RimCardProps {
    rim: RimGroupSearchResultDTO;
    carId?: string;
    carManufacturer?: string,
    carTradeName?: string,
    rimSelectionCallback: Function;
    getWishlistCallback: Function;
    wishlistEntries?: WishListDTO[];
    selectedRim?: RimItemDTO;
    rimSizeData?: RimSizeData;
    isListView?: boolean;
}

export default function RimCard(props: RimCardProps) {
    const {
        rim,
        carId
    } = props;
    const {t} = useTranslation();
    const location = useLocation();
    const dispatch = useDispatch();
    let rimDetailUrl = {
        pathname: `../felgen/details/${props.rim.rimId}${addSeoData(true)}${props.carId ? '/' + encodeURIComponent(props.carId) + addSeoData(false) : ''}`,
        search: createSafeSearchParams(),
    };

    const selectedRimSize = props.selectedRim?.size ? props.selectedRim?.size : props.rimSizeData.minSize;
    const isSelected = rim.items?.find((item) => item.rimId === props.selectedRim.rimId);
    const rimImageSrc = rim.imagePerspectiveUrl ? rim.imagePerspectiveUrl : rim.imageFrontUrl;
    const isFavorite: boolean = !!props.wishlistEntries?.find((entry) => entry.productId === props.rim.rimId);
    let rimSizes = [];
    let selectedRimId = props.selectedRim.rimId;
    let isSmallScreen = useMediaQuery("(max-width: 991.98px)");

    if(rim.items && rim.items.length > 0) {
        rimSizes = [];
        let addedNumbers = [];
        for(const item of rim.items) {
            const rimSize = item.size;
            let isSelectedRimSize = selectedRimSize === rimSize;

            if(isSelectedRimSize) {
                selectedRimId = item.rimId;
            }

            if(props.rimSizeData && props.rimSizeData.maxSize > 0) {
                for(let i = props.rimSizeData.minSize; i <= props.rimSizeData.maxSize; i++) {
                    if(addedNumbers.some(x => i == x)) {
                        continue;
                    }

                    if(rimSize == i) {
                        rimSizes.push(<div
                            key={i}
                            className={`rim-size p-0 col-lg-1 small rim-size-${rimSize} ${isSelectedRimSize ? "selected " : ""} m-1`}
                            onClick={(e) => changeRimSize(e, item)}
                        >
                            {rimSize}
                        </div>);
                        addedNumbers.push(i);
                        continue;
                    }

                    if(rim.items.some(x => x.size == i) && !addedNumbers.some(x => x == i)) {
                        continue;
                    }

                    rimSizes.push(<Tooltip key={i} overlay={<span>{t("RIMS.NOT_AVAILABLE_IN_SIZE")}</span>}
                                           placement={"top"}>
                        <div
                            className={`rim-size col-lg-1 p-0 small m-1`}
                            style={{
                                backgroundColor: "#c0c0c0",
                                color: "#6c6c6c"
                            }}
                        >{i}</div>
                    </Tooltip>);
                    addedNumbers.push(i);
                }
            } else {
                rimSizes.push(<div
                    key={rimSize}
                    className={`rim-size col-lg-1 small p-0 rim-size-${rimSize} ${isSelectedRimSize && "selected"} ${rim.items.length > 1 ? " mx-1" : ""}`}
                    onClick={(e) => changeRimSize(e, item)}
                >
                    {rimSize}
                </div>);
            }
        }
    }

    rimSizes.sort((a, b) => a.key > b.key ? 1 : -1);


    function createSafeSearchParams(): string {
        let params = new URLSearchParams(location.search);
        params.delete('rimRearId');
        return params.toString();
    }

    function addSeoData(setRimData: boolean): string {
        if(setRimData && props.rim) {
            return `-${StringHelper.toSnakeCase(props.rim.manufacturer)}_${StringHelper.toSnakeCase(props.rim.name)}`
        } else if(!setRimData && props.carId) {
            return `-${StringHelper.toSnakeCase(props.carManufacturer)}_${StringHelper.toSnakeCase(props.carTradeName)}`
        }
        return "";
    }


    function getPrice() {
        if(rim.items) {
            for(const item of rim.items) {
                if(item.size === selectedRimSize) {
                    return item.price?.minSellInPriceNet ? (+item.price?.minSellInPriceNet * 4) : undefined;
                }
            }
            return rim.items[0].price?.minSellInPriceNet ? (+rim.items[0].price?.minSellInPriceNet * 4) : undefined;
        }
    }

    function getRecommendedRetailPrice() {
        if(rim.items) {
            for(const item of rim.items) {
                if(item.size === selectedRimSize) {
                    return item.recommendedRetailPrice * 4;
                }
            }
            return rim.items[0].recommendedRetailPrice * 4;
        }
    }

    function changeFavorite(evt: React.MouseEvent<HTMLElement>) {
        evt.stopPropagation();
        let {
            getWishlistCallback,
            wishlistEntries
        } = props;

        const wishlistEntry = wishlistEntries?.find((entry) => entry.productId === rim.rimId);

        if(wishlistEntry) {
            deleteArticleFromWishlist(wishlistEntry.id)
                .then((res) => {
                    dispatch(decrement());
                    getWishlistCallback();
                })
                .catch((error) => {
                    console.error("deleteArticleFromWishlist", error);
                });
        } else {
            addArticleToWishlist({
                description: rim.manufacturer,
                imageLink: rim.imagePerspectiveUrl,
                itemType: "RIM",
                productId: rim.rimId,
                wishlistId: 1,
            })
                .then((res) => {
                    dispatch(update(res.wishlistEntries?.length))
                    getWishlistCallback(res.wishlistEntries);
                })
                .catch((error) => {
                    console.error("addArticleToWishlist", error);
                });
        }
    }

    function changeRimSize(e, rimItem) {
        e.stopPropagation();
        props.rimSelectionCallback(rimItem);
    }

    function selectRim() {
        const rimItem = rim.items?.find((item) => item.size === selectedRimSize);
        if(rimItem) {
            props.rimSelectionCallback(rimItem);
        } else {
            props.rimSelectionCallback(rim.items?.[0]);
        }
    }

    function detailsClicked(evt) {
        evt.stopPropagation();
        AnalyticsHelper.trackRimClick({
            ...rim,
            rimId: selectedRimId
        });
    }

    function getRimDeliveryStatus() {
        let states = new Set(props?.rim?.items?.map(i => i.status));
        if(props.carId) {
            let availableRims = props.rim?.items?.filter(i => i.size === props.selectedRim.size);
            if(availableRims.length > 0) {
                states = new Set(availableRims.map(i => i.status));
            }

        }
        if(states.has(DeliveryStatus.NO_STOCK)) {
            return DeliveryStatus.NO_STOCK;
        } else if(states.has(DeliveryStatus.VENDOR_STOCK)) {
            return DeliveryStatus.VENDOR_STOCK;
        } else if(states.has(DeliveryStatus.IN_STOCK)) {
            return DeliveryStatus.IN_STOCK;
        }
        return DeliveryStatus.NO_STOCK;
    }

    return <>
        {props.isListView ? <ListViewRimCard rim={props.rim} carId={props.carId}
                                             rimSelectionCallback={props.rimSelectionCallback}
                                             getWishlistCallback={props.getWishlistCallback}
                                             wishlistEntries={props.wishlistEntries}
                                             selectedRim={props.selectedRim}
                                             selectRim={selectRim}
                                             detailsClicked={detailsClicked}
                                             changeFavorite={changeFavorite}
                                             getPrice={getPrice}
                                             getRecommendedRetailPrice={getRecommendedRetailPrice}
                                             rimSizesList={rimSizes}
                                             isFavorite={isFavorite}
                                             rimImageSrc={rimImageSrc}
                                             isSelected={isSelected}
                                             rimDetailUrl={rimDetailUrl}
                                             isSmallScreen={isSmallScreen}
                                             deliveryStatus={getRimDeliveryStatus()}
        /> : <GridViewRimCard rim={props.rim} carId={props.carId}
                              rimSelectionCallback={props.rimSelectionCallback}
                              getWishlistCallback={props.getWishlistCallback}
                              wishlistEntries={props.wishlistEntries}
                              selectedRim={props.selectedRim}
                              selectRim={selectRim}
                              detailsClicked={detailsClicked}
                              changeFavorite={changeFavorite}
                              getPrice={getPrice}
                              getRecommendedRetailPrice={getRecommendedRetailPrice}
                              rimSizesList={rimSizes}
                              isFavorite={isFavorite}
                              rimImageSrc={rimImageSrc}
                              isSelected={isSelected}
                              rimDetailUrl={rimDetailUrl}
                              isSmallScreen={isSmallScreen}
                              deliveryStatus={getRimDeliveryStatus()}
        />}
    </>;
}
