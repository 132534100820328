import React from "react";
import { Markdown } from "../../../components/Markdown/Markdown";
import { scrollToTopFn } from "../../../components/ScrollToTop";
import { getStaticPage } from "../../../redux-store/api/StrapiApi";

class SeoText extends React.Component<any, { content: string }> {

    constructor(props) {
        super(props);
        this.state = { content: "" };
    }

    componentDidMount() {
        scrollToTopFn();
        getStaticPage("seo_text").then((res) => {
            this.setState({ content: res?.Content });
        });
    }

    render() {
        return (
            <div className="seo-wrapper">
                <div className="container">
                    <Markdown content={this.state.content} />
                </div>
            </div>
        );
    }
}

export default SeoText;
