import React, { useContext, useState } from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import UserContext from "../../context/UserContext";
import Login from "../Login/Login";
import {UserContextType} from '../../context/UserContextType';
import {updateCartPreview} from "../../redux-store/actions/cartPreviewAction";
import {postLogout} from "../../redux-store/api";
import {ReactComponent as UserSvg} from '../../assets/icons/svg/user.svg';

interface Props {
    updateCartPreview: Function;
}

function UserSettings(props: Props) {
    const {t} = useTranslation();
    const {loggedIn, logoutUser} = useContext(UserContext) as UserContextType;

    function logout() {
        postLogout().then(() => {
            logoutUser();
            props.updateCartPreview();
        });
    }

    return (
        <>
            {!loggedIn &&
                <Login/>
            }
            {loggedIn &&
                <div className="user-settings-wrapper">
                    <div className="container">
                        <h1 className="text-dark pb-4"><i>{t("SETTINGS.TITLE")}</i></h1>
                        <div className="row">
                            <div className="col-12 col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                <Link to="/einstellungen/passwort">
                                    <div className="menu-card">
                                        <div>
                                            <i className="fas fa-key"/>
                                            <div>{t("SETTINGS.CHANGE_PASSWORD")}</div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-12 col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                <Link to="/einstellungen/bestellungen">
                                    <div className="menu-card">
                                        <div>
                                            <i className="fas fa-history"/>
                                            <div>{t("SETTINGS.ORDER_HISTORY.TITLE")}</div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-12 col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                <Link to="/einstellungen/nutzerdaten">
                                    <div className="menu-card">
                                        <div>
                                            <UserSvg height={'4rem'} className={'text-highlight mb-4'}/>
                                            <div>{t("SETTINGS.CHANGE_USER_DATA")}</div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-12 col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                <div className="menu-card" onClick={()=> logout()}>
                                    <div>
                                        <i className="fas fa-sign-out-alt"/>
                                        <div>{t("SETTINGS.SIGN_OUT")}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

const mapDispatchToProps = {
    updateCartPreview
};

export default connect(null, mapDispatchToProps)(UserSettings);
