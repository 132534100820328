import React from "react";
import {useTranslation} from "react-i18next";
import Tooltip from "rc-tooltip";
import {ReactComponent as CheckSvg} from '../../assets/icons/svg/check.svg';

interface RatePayProps {
    className?: string;
    showCheckIcon?: boolean;
}

export default function RatePayInfo(props: RatePayProps) {
    const [t] = useTranslation();

    const classes = `rate-pay-info ${props.className}`

    return (
        <div className={classes + ' p-0 m-0'}>
            {props.showCheckIcon && <CheckSvg height={'0.75rem'} color={'var(--bs-green)'}/>}

            <Tooltip trigger={['hover', 'click']}
                     overlay={<span className={'m-0'}>{t('PAYMENT_METHOD_INFORMATION.RATEPAY')}</span>}>
                <img className={"rate-pay-logo"} src={"/payment/paypal.png"}/>
            </Tooltip>
        </div>
    );
}
