import React, {Component, useEffect, useState} from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import {AnalyticsHelper} from "../../../analytics/AnalyticsHelper";
import { Markdown } from "../../../components/Markdown/Markdown";
import {urlParamsToObject} from "../../../helper/Helper";
import {withRouter, WithRouterProps} from "../../../helper/withRouter";
import {PaymentStatus} from "../../../models/checkout/PaymentStatusDTO";
import {completePayPal} from "../../../redux-store/api/PaymentApi";
import { getStaticPage } from "../../../redux-store/api/StrapiApi";
import {ChangeOrderPayment} from "./ChangeOrderPayment";

class PayPalComplete extends Component<
    WithRouterProps<WithTranslation>,
    { loading: boolean; text: string; orderId: string; paymentStatus?: PaymentStatus }
> {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            text: "",
            orderId: "",
        };
    }


    componentDidMount() {
        const urlParams = new URLSearchParams(this.props.location.search);
        completePayPal(urlParamsToObject(urlParams))
            .then((paypalRes) => {
                if (paypalRes.orderId) {
                    this.setState({orderId: paypalRes.orderId + "", paymentStatus: paypalRes.status});
                }
                if ([PaymentStatus.ACCEPTED, PaymentStatus.COMPLETED].includes(paypalRes.status)) {
                    window.gtag('event', 'conversion', {
                        'send_to': 'AW-1065970570/mDGHCK36mmAQitel_AM',
                        'value': paypalRes.order.sumGross,
                        'currency': 'EUR',
                        'transaction_id': paypalRes.orderId
                    });
                    AnalyticsHelper.trackPurchaseByOrder(paypalRes.order)
                }
            })
            .finally(() => this.setState({loading: false}));
        getStaticPage("order_confirmation").then((page) => {
            this.setState({
                text: page?.Content
            });
        });
    }

    render() {
        const {t} = this.props;
        if (this.state.loading) {
            return <div className="container pay-pal-complete-wrapper">
                <div className="info d-flex flex-column align-items-center">
                    <h3 className="mb-2 text-center">{t("PAY_PAL.COMPLETE.PROCESSING_PAYMENT")}</h3>
                    <div className="paypal-loader"></div>
                </div>
            </div>
        } else {
            return (
                this.state.paymentStatus === PaymentStatus.REJECTED ? (
                    <ChangeOrderPayment orderId={this.state.orderId}/>
                ) : (
                    <div className="container pay-pal-complete-wrapper">
                        <div className="info">
                            <h2 className="mb-2 text-center">{t("PAY_PAL.COMPLETE.SUCCESSFUL")}</h2>
                            <p className="text-center">{t("PAY_PAL.COMPLETE.PROCESSING_ORDER")}</p>
                            {this.state.text && (
                                <div className="confluence-content">
                                    <Markdown content={(this.state.text || "").replace(/ORDER_ID/gi, this.state.orderId)} />
                                </div>
                            )}
                        </div>
                    </div>
                )
            );
        }
    }
}

export default withTranslation()(withRouter(PayPalComplete));

