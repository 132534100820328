import React from "react";

export interface RimImageLoadingProps {
    minHeight: number
    minWidth: number
}
export function RimImageLoading ({minHeight, minWidth}: RimImageLoadingProps) {
    return <div
        className="rim-image-loading mt-md-3"
        style={{
            minHeight,
            minWidth,
        }}
    >
        <div className="lds-facebook">
            <div />
            <div />
            <div />
            <div />
        </div>
    </div>
}
