import React, {useEffect, useState} from "react";
import {useMediaQuery} from "../../helper/useMediaQuery";

interface Props {
    amount: number;
    handleAmountChange: Function;
    onlyEvenNumbers?: boolean;
    readonly?: boolean;
}

function AmountInput({amount, handleAmountChange, onlyEvenNumbers, readonly = false}: Props) {
    const isSmallScreen = useMediaQuery("(max-width: 991.98px)");
    const [isFocused, setIsFocused] = useState(false);
    const [newAmount, setNewAmount] = useState(amount);

    useEffect(() => {
        checkAmount(newAmount)
    }, [isFocused]);

    function onAmountChanged(newAmount: number) {
        checkAmount(newAmount);
    }

    function increase () {
        checkAmount(newAmount + 1);
    }

    function decrease () {
        checkAmount(newAmount - 1);
    }

    function checkAmount(newAmount: number) {
        let targetValue = newAmount;
        if (onlyEvenNumbers && !isFocused) {
            targetValue = newAmount % 2 === 1
                ? newAmount > amount ? newAmount + 1  : newAmount - 1
                : newAmount;
            targetValue = Math.max(targetValue, 2);
        } else if (!isFocused){
            targetValue = Math.max(newAmount, 1);
        }

        setNewAmount(targetValue);

        if(!isFocused) {
            if(targetValue !== amount) {
                handleAmountChange(targetValue);
            }
        }
    }



    return (
        <div className="custom-amount-input">
            <div className="amount-input-field-wrapper">
                <input type="number"
                       value={newAmount}
                       className="amount-field"
                       onChange={(e) => onAmountChanged(+e.target.value)}
                       onFocus={() => setIsFocused(true)}
                       onBlur={() => setIsFocused(false)}
                       disabled={readonly}
                />
            </div>
            {isSmallScreen && !readonly &&
                <>
                    <div className="circle ms-2" onClick={decrease}>
                        <div className={"d-flex justify-content-center align-items-center h-100"}>
                            <i className="fas fa-minus  "/>
                        </div>
                    </div>
                    <div className="circle ms-2" onClick={increase}>
                        <div className={"d-flex justify-content-center align-items-center h-100"}>
                            <i className="fas fa-plus "/>
                        </div>
                    </div>
                </>
            }
        </div>
    );
}

export default AmountInput;
