import React from "react";
import {useTranslation} from "react-i18next";
import {DeliveryTrackingDTO} from "../../../../models/order/DeliveryTrackingDTO";
import {useMediaQuery} from "../../../../helper/useMediaQuery";

interface Props {
    trackingEntries: DeliveryTrackingDTO[];
}

export default function Tracking({trackingEntries}: Props) {
    const [t] = useTranslation();
    const isSmallScreen = useMediaQuery('(max-width: 767.98px)');

    return (
        <>
            <div className="row tracking-header p-2">
                {t("SETTINGS.ORDER_HISTORY.TRACKING.TITLE")}
            </div>
            {trackingEntries?.length > 0 &&
            <>
                {isSmallScreen && <>
                    {trackingEntries?.map(tracking => (
                <div className="row tracking-mobile" key={tracking.trackingReference}>
                    <div className="col-6 fw-bold">
                        {t("SETTINGS.ORDER_HISTORY.TRACKING.CARRIER")}
                    </div>
                    <div className="col-6">
                        {tracking.carrierName}
                    </div>
                    <div className="col-6 fw-bold">
                        {t("SETTINGS.ORDER_HISTORY.TRACKING.REFERENCE")}
                    </div>
                    <div className="col-6">
                        {tracking.trackingReference}
                    </div>
                    <div className="col-6 fw-bold">
                        {t("SETTINGS.ORDER_HISTORY.TRACKING.LINK")}
                    </div>
                    <div className="col-6">
                            {tracking.trackingLink &&
                                <a href={tracking.trackingLink} target="_blank" rel="noreferrer">
                                    {t("SETTINGS.ORDER_HISTORY.TRACKING.TRACK_PACKAGE")} <i
                                    className="fa fa-external-link-alt"/>
                                </a>
                            }
                            {!tracking.trackingLink &&
                                <span>{t("SETTINGS.ORDER_HISTORY.TRACKING.NO_LINK")}</span>
                            }
                    </div>
                </div>))}
                </>}
                {!isSmallScreen && <>
                <div className="row order-overview-header-row pt-3 pb-2">
                    <div className="col-4 fw-bold">
                        {t("SETTINGS.ORDER_HISTORY.TRACKING.CARRIER")}
                    </div>
                    <div className="col-4 fw-bold">
                        {t("SETTINGS.ORDER_HISTORY.TRACKING.REFERENCE")}
                    </div>
                    <div className="col-4 fw-bold">
                        {t("SETTINGS.ORDER_HISTORY.TRACKING.LINK")}
                    </div>
                </div>
                {trackingEntries?.map(tracking => (
                    <div className="row order-overview-panel mb-1" key={tracking.trackingReference}>
                       <div className="col-4">
                            {tracking.carrierName}
                        </div>
                        <div className="col-4">
                            {tracking.trackingReference}
                        </div>
                        <div className="col-4">
                            {tracking.trackingLink &&
                                <a href={tracking.trackingLink} target="_blank" rel="noreferrer">
                                    {t("SETTINGS.ORDER_HISTORY.TRACKING.TRACK_PACKAGE")} <i
                                    className="fa fa-external-link-alt"/>
                                </a>
                            }
                            {!tracking.trackingLink &&
                                <span>{t("SETTINGS.ORDER_HISTORY.TRACKING.NO_LINK")}</span>
                            }
                        </div>
                    </div>
                ))}
                </>}
            </>
            }
            {(!trackingEntries || trackingEntries?.length === 0) &&
            <div className="row">
                <div className="col-12 py-2">
                    {t("SETTINGS.ORDER_HISTORY.TRACKING.NO_ENTRIES")}
                </div>
            </div>
            }
        </>
    );
}
